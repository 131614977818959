<template>
    <div id="sign-details">
        <template v-if="status">
            <span class="center-span">{{ status.message }}</span>
            <div class="center-group">
                <img v-if="status.success" src="@/assets/success_big.svg" />
                <img v-else src="@/assets/error_big.svg" />
            </div>
            <ButtonGroup class="center-group" v-if="!status.success">
                <Button variant="contour" size="secondary" @click="cancel">Avbryt</Button>
            </ButtonGroup>
        </template>
        <template v-if="authenticationMethod === 'BankID' && !status">
            <form @submit.prevent="sign" v-if="!sessionParams.polling">
                <div class="center">
                    <span>Personnummer (ÅÅÅÅMMDDNNNN)</span>
                    <input disabled :value="personId" />
                </div>
                <ButtonGroup class="center-group">
                    <Button size="secondary" type="submit" :disabled="signActive"> Signera</Button>
                    <Button variant="contour" size="secondary" @click="cancel"> Avbryt</Button>
                </ButtonGroup>
            </form>
            <div v-else>
                <span class="center-span">Öppna Bank-ID appen på din mobila enhet.</span>
                <div class="center-group">
                    <img class="spin" src="@/assets/loader.svg" />
                </div>
                <div class="center-group">
                    <div class="button-span">Signerar...</div>
                    <Button variant="contour" size="secondary" @click="cancel"> Avbryt</Button>
                </div>
            </div>
        </template>
        <template v-if="authenticationMethod === 'NetID' && !status">
            <form @submit.prevent="sign" v-if="!sessionParams.polling">
                <span class="center-span" style="min-height: 162px"
                    >Kontrollera att ditt SITHS-kort sitter i kortläsaren.</span
                >
                <ButtonGroup class="center-group">
                    <Button size="secondary" type="submit">Signera</Button>
                    <Button variant="contour" size="secondary" @click="cancel"> Avbryt</Button>
                </ButtonGroup>
            </form>
            <div v-else>
                <span class="center-span"
                    >Net ID Access-inloggningen öppnas i ett nytt webbläsarfönster. Du kan behöva godkänna en förfrågan
                    från webbläsaren om att öppna fönstret. Glöm inte att sätta i ditt kort för att legitimera
                    dig.</span
                >
                <div class="center-group">
                    <img class="spin" src="@/assets/loader.svg" />
                </div>
                <div class="center-group">
                    <div class="button-span">Signerar...</div>
                    <Button variant="contour" size="secondary" @click="cancel"> Avbryt</Button>
                </div>
            </div>
        </template>
        <template v-if="authenticationMethod === 'Basic' && !status">
            <form @submit.prevent="sign">
                <div class="center narrow-margin-bottom">
                    <span>Användarnamn</span>
                    <text-widget placeholder="Användarnamn" disabled :value="username" />
                    <span>Lösenord</span>
                    <input type="password" placeholder="Lösenord" v-model="password" autofocus />
                </div>
                <ButtonGroup class="center-group">
                    <Button size="secondary" type="submit" :disabled="signActive"> Signera</Button>
                    <Button variant="contour" size="secondary" @click="cancel"> Avbryt</Button>
                </ButtonGroup>
            </form>
        </template>
    </div>
</template>
<script>
import widgets from "@/components/widgets";
import { getAuthenticationMethod, getErrorMessage, getFallbackSignMethod, klinikenApi } from "@/api";
import { init, poll } from "@/utils/auth";
import { mapGetters } from "vuex";
import { openDialog } from "@/utils";
import dialogEvents from "@/dialogEvents";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";

export default {
    name: "Sign",
    components: {
        ...widgets,
        Button,
        ButtonGroup,
    },
    computed: {
        ...mapGetters("userData", ["personId", "username"]),
    },
    data() {
        return {
            password: "",
            sessionParams: {
                polling: false,
                sessionId: null,
            },
            authenticationMethod: getAuthenticationMethod() || getFallbackSignMethod(),
            status: null,
            data: this.$attrs.response, // params, config, url, callback, errorCallback
            signActive: false,
        };
    },
    methods: {
        async sign() {
            if (this.signActive) return;
            this.signActive = true;
            this.status = null;
            let redirectUrl = null;
            if (this.authenticationMethod === "BankID" || this.authenticationMethod === "NetID") {
                await init({
                    apiUrl:
                        this.authenticationMethod === "BankID"
                            ? this.data.url + "sign/bankid/init/"
                            : this.data.url + "sign/netid/init/",
                    params: this.data.params,
                    config: this.data.config,
                    responseHandler: (data) => {
                        this.sessionParams.sessionId = data.sessionId;
                        this.sessionParams.polling = true;
                        redirectUrl = data.redirectUrl;
                    },
                });
                if (!this.sessionParams.polling) {
                    if (this.data.errorCallback) this.data.errorCallback("Ett oväntat fel har uppstått vid signering.");
                    return;
                }
                if (this.authenticationMethod === "NetID") {
                    if (!redirectUrl) return;
                    window.location = redirectUrl;
                }
                await poll({
                    state: this.sessionParams,
                    apiUrl:
                        this.authenticationMethod === "BankID"
                            ? this.data.url + "sign/bankid/poll/"
                            : this.data.url + "sign/netid/poll/",
                    responseHandler: (status, response) => {
                        this.sessionParams.polling = false;
                        if (status === "error" || status === "failed") {
                            this.status = { success: false, message: response };
                            this.sessionParams.polling = false;
                            if (this.data.errorCallback) this.data.errorCallback(response);
                        } else {
                            this.status = {
                                success: true,
                                message: "Toppen! Du har nu signerat.",
                            };
                            this.data.callback(response);
                            this.sessionParams.polling = false;
                            setTimeout(this.close, 1500);
                        }
                    },
                });
            } else if (this.authenticationMethod === "Basic") {
                this.data.params.password = this.password;
                klinikenApi
                    .post(this.data.url + "sign/basic/", this.data.params, this.data.config)
                    .then((response) => {
                        this.status = {
                            success: true,
                            message: "Toppen! Du har nu signerat.",
                        };
                        this.data.callback(response.data);
                        this.sessionParams.polling = false;
                        setTimeout(this.close, 1500);
                    })
                    .catch((e) => {
                        openDialog(getErrorMessage(e), "error");
                        if (this.data.errorCallback) this.data.errorCallback(e);
                    });
            }
            this.signActive = false;
        },

        close() {
            dialogEvents.$emit("closePopup");
        },

        cancel() {
            this.sessionParams.polling = false;
            this.close();
            if (this.data.errorCallback) this.data.errorCallback("Signering avbruten.");
        },
    },
};
</script>
<style lang="sass" scoped>
@import "@/style/variables"

@-ms-keyframes spin
    0%
        -ms-transform: rotate(0deg)
    100%
        -ms-transform: rotate(360deg)

@-moz-keyframes spin
    0%
        -moz-transform: rotate(0deg)
    100%
        -moz-transform: rotate(360deg)

@-webkit-keyframes spin
    0%
        -webkit-transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

#sign-details
    display: block
    opacity: 1
    background-color: white
    position: relative
    padding: 23px 104px

    .spin
        -webkit-animation-name: spin
        -webkit-animation-duration: 4000ms
        -webkit-animation-iteration-count: infinite
        -webkit-animation-timing-function: linear
        -moz-animation-name: spin
        -moz-animation-duration: 4000ms
        -moz-animation-iteration-count: infinite
        -moz-animation-timing-function: linear
        -ms-animation-name: spin
        -ms-animation-duration: 4000ms
        -ms-animation-iteration-count: infinite
        -ms-animation-timing-function: linear
        animation-name: spin
        animation-duration: 4000ms
        animation-iteration-count: infinite
        animation-timing-function: linear

    button
        min-width: 228px

        &:not(:last-of-type)
            margin-right: 24px

    .button-span
        min-width: 260px
        margin-right: 24px
        height: 37px
        color: #354052
        font-size: 20px
        letter-spacing: 0
        line-height: 28px
        text-align: center
        display: inline-block

    .center-span
        display: block
        width: 100%
        color: #354052
        font-size: 20px
        letter-spacing: 0
        line-height: 28px
        text-align: center

    .center-group
        width: 100%
        text-align: center
        margin-top: 46px

    .center
        margin-top: 26px

        span
            height: 21px
            color: #728296
            font-size: 16px
            font-weight: 500
            letter-spacing: 0
            line-height: 21px
            display: block

        input
            height: 46px
            width: 100%
            border-radius: 4px
            border: $color-input-border solid 1px
            background-color: #FFFFFF
            color: #354052
            font-size: 16px
            letter-spacing: 0
            line-height: 21px
            margin-bottom: 34px

        &.narrow-margin-bottom
            margin-top: 0

            input
                margin-bottom: 20px

    .login-header
        height: 48px
        width: 100%
        color: #277692
        font-size: 38px
        letter-spacing: 0
        line-height: 42px
        text-align: center

    .customer
        height: 29px
        width: 100%
        color: #277692
        font-size: 16px
        letter-spacing: 0
        line-height: 21px
        text-align: center

    .login-button
        box-sizing: border-box
        height: 78px
        width: 728px
        border: 1px solid #38A7CF
        border-radius: 2px
        margin: 17px 113px 17px 113px
        background-color: white
        font-family: Roboto Medium
        color: #2694BC
        font-size: 16px
        font-weight: 500
        letter-spacing: 0
        line-height: 21px
        text-align: left

        &:hover
            background-color: #C8E6EC

        &.basic
            padding-left: 111px

        &.bankid
            img
                padding-left: 45px
                padding-right: 32px

        &.siths
            img
                padding-left: 38px
                padding-right: 23px
</style>
