import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { flashMessage, openDialog } from "@/utils";
import Sign from "@/components/Sign.vue";
import dialogEvents from "@/dialogEvents";

const STATUS_EMPTY = "empty";
const STATUS_MODIFIED = "modified";
const STATUS_SAVING = "saving";
const STATUS_SAVED = "saved";
const STATUS_SIGNED = "signed";
const STATUS_SIGNING = "signing";
const STATUS_SIGN_FAILED = "sign failed";

const SAVE_STATUSES = [STATUS_EMPTY, STATUS_MODIFIED, STATUS_SAVED];
const SIGN_STATUSES = [STATUS_EMPTY, STATUS_MODIFIED, STATUS_SAVED, STATUS_SIGNED];
const WORKING_STATUSES = [STATUS_SIGNING, STATUS_SAVING];

const copy = (obj) => JSON.parse(JSON.stringify(obj));

const checkType = (oldType) => {
    switch (oldType) {
        case "CommunicableDisease":
            return "cd";
        case "Treatment":
            return "t";
        case "TreatmentType":
            return "t";
        case "UnstructuredAlertInformation":
            return "u";
        case "RestrictionOfCare":
            return "roc";
        case "OtherHyperSensitivity":
            return "ohs";
        case "PharmaHyperSensitivity":
            return "phs";
        case "SeriousDisease":
            return "sd";
        default:
            return oldType;
    }
};

const waitForPoll = async ({ state }) => {
    let failsafeCounter = 300; // Hard timeout of 5 minutes/300 seconds
    while (state.polling && failsafeCounter--) {
        await pollTimeout();
    }

    return new Promise((resolve) => resolve());
};

const pollTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
};

const getDefaultState = () => {
    return {
        namespaced: true,
        alertInformationSymbol: [],
        alertInformationList: [],
        details: {},
        status: STATUS_EMPTY,
        polling: false,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        alertInformationSymbol(state) {
            return state.alertInformationSymbol;
        },
        alertInformationList(state) {
            return state.alertInformationList;
        },
        details(state) {
            return state.details;
        },
        isSaveable(state) {
            return SAVE_STATUSES.includes(state.status) && !state.anteckning.is_signed;
        },
        isSignable(state) {
            return SIGN_STATUSES.includes(state.status);
        },
        isWorking(state) {
            return WORKING_STATUSES.includes(state.status);
        },
        isModified(state) {
            return state.status === STATUS_MODIFIED;
        },
        status(state) {
            return state.status;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        resetAlertInformation(state) {
            state.alertInformation = {};
        },
        resetAlertInformationList(state) {
            state.alertInformationList.splice(0);
        },
        setAlertInformationList(state, data) {
            state.alertInformationList = data;
        },
        setAlertInformationSymbol: (state, data) => {
            state.alertInformationSymbol = data;
        },
        setPolling(state, polling) {
            state.polling = polling;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setDetails: (state, data) => {
            state.details = data;
        },
    },
    actions: {
        async resetAlertInformation({ commit }) {
            commit("resetAlertInformation");
        },
        async saveAlertInformation(context, alertInformation) {
            const response = await klinikenApi
                .post(
                    `uppmarksamhetssignal/${alertInformation.type}/`,
                    alertInformation,
                    getConfig({}, "Uppmärksamhetssignaler")
                )
                .catch((e) => openDialog(getErrorMessage(e), "error"));

            response.data.type = alertInformation.type;
            if (!alertInformation.comingFromSign) {
                openDialog("Uppmärksamhetssignalen sparad", response.data);
            }
            return response;
        },
        async loadAlertInformationList({ commit }, data, failSilently = false) {
            const alertInformationListFetch = await klinikenApi.get(
                `uppmarksamhetssignal/list/${data.patientId}/`,
                getConfig()
            );
            let alertInformationList = alertInformationListFetch.data;
            try {
                if (alertInformationList) {
                    commit("setAlertInformationList", alertInformationList);
                } else {
                    openDialog("Den valda patienten har inga uppmärksamhetssignaler", "warning");
                }
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta uppmärksamhetssignaler" + getErrorMessage(e), "warning");
                }
            }
        },
        async loadDetailsInformation({ commit }, information, failSilently) {
            let type;
            type = await checkType(information.type);
            const detailsFetch = await klinikenApi.get(
                `uppmarksamhetssignal/${type}/${information.pk}/`,
                getConfig({ params: { patient: information.id } }, "Uppmärksamhetssignaler")
            );

            let details = detailsFetch.data;

            try {
                if (details) {
                    commit("setDetails", details);
                    return details;
                } else {
                    openDialog("Uppmärksamhetssignalen hade ingen ytterligare information", "warning");
                }
            } catch (e) {
                if (!failSilently) {
                    openDialog("Uppmärksamhetssignalen kunde inte hämtas" + getErrorMessage(e), "warning");
                }
            }
        },
        async loadAlertInformationSymbol({ commit }, data, failSilently = false) {
            const alertInformationSymbolFetch = await klinikenApi.get(
                `uppmarksamhetssignal/symbol/${data.patientId}`,
                getConfig()
            );

            let alertInformationSymbol = alertInformationSymbolFetch.data;

            try {
                if (alertInformationSymbol) {
                    commit("setAlertInformationSymbol", alertInformationSymbol);
                } else {
                    openDialog("Den valda patienten har inga uppmärksamhetssignaler", "warning");
                }
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta uppgifter om uppmärksamhetssignaler" + getErrorMessage(e), "warning");
                }
            }
        },
        async setTimePeriodEndAlertInformation(context, data) {
            let type;
            type = checkType(data.type);

            const inactualRequest = await klinikenApi.patch(
                `uppmarksamhetssignal/${type}/${data.pk}/set_validityTimePeriodEnd/`,
                {},
                getConfig({ params: { patient: data.patient } }, "Uppmärksamhetssignaler")
            );
            return inactualRequest;
        },
        async makuleraAlertInformation(context, data) {
            let type;
            type = checkType(data.type);

            const makuleraAlertRequest = await klinikenApi.patch(
                `uppmarksamhetssignal/${type}/${data.pk}/nullify/`,
                {
                    nullifiedReason: data.reason,
                },
                getConfig()
            );
            return makuleraAlertRequest;
        },

        async sign({ state, commit }, alert) {
            const config = getConfig({}, "Uppmärksamhetssignaler");

            //status at this point can be either SAVED or SIGNED, both are allowed. Store the status, since if signing fails it should revert to initial status.
            let initialStatus = state.status;
            commit("setStatus", STATUS_SIGNING);

            let signingAlert = copy(alert);
            let type = checkType(signingAlert.typeOfAlertInformation);

            let url = `/uppmarksamhetssignal/${type}/`;
            if (signingAlert.pk !== null && signingAlert.pk !== undefined) {
                url = `${url}${signingAlert.pk}/`;
            }

            commit("setPolling", true);
            dialogEvents.$emit("openPopup", {
                title: "Signera uppmärksamhetssignal",
                component: Sign,
                dismissable: false,
                data: {
                    config,
                    params: { ...signingAlert, ...{ sessionId: state.sessionId } },
                    url: url,
                    callback: (data) => {
                        commit("setPolling", false);
                        if (data.is_signed) {
                            flashMessage("Uppmärksamhetssignal signerad");
                            commit("setStatus", STATUS_SIGNED);
                        }
                    },
                    errorCallback: () => {
                        commit("setPolling", false);
                    },
                },
            });
            await waitForPoll({ state });

            // if state at this point is SIGNING, something went wrong. If initialstatus was SIGNED, set to SIGN_FAILED, else to SAVED
            if (state.status === STATUS_SIGNING && initialStatus === STATUS_SIGNED)
                commit("setStatus", STATUS_SIGN_FAILED);
            else if (state.status === STATUS_SIGNING) commit("setStatus", STATUS_SAVED);

            return new Promise((resolve) => resolve());
        },
    },
};
