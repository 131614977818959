import { setJWT, setProfile, setRefreshToken } from "@/api";

const noNull = (str) => {
    return str || "";
};

const getDefaultState = () => {
    return {
        currentProfile: null,
        userData: {},
        profiles: [],
        loginMethod: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setProfiles(state, profiles) {
            state.profiles = profiles;
        },
        setUserData(state, userData) {
            state.userData = userData;
        },
        setCurrentProfile(state, currentProfile) {
            state.currentProfile = currentProfile;
            setProfile(currentProfile);
            sessionStorage.setItem("Profile", currentProfile);
        },
        setLoginMethod(state, method) {
            state.setLoginMethod = method;
        },
    },
    getters: {
        personId(state) {
            return state.userData.personId || "[Saknas]";
        },
        fullName(state) {
            return state.userData.first_name || state.userData.last_name
                ? noNull(state.userData.first_name) + " " + noNull(state.userData.last_name)
                : "Användare saknas";
        },
        initials(state) {
            return state.userData.first_name || state.userData.last_name
                ? noNull(state.userData.first_name).slice(0, 1) + noNull(state.userData.last_name).slice(0, 1)
                : "--";
        },
        username(state) {
            return state.userData.username;
        },
        HSAId(state) {
            return state.userData.HSAId;
        },
        arbKod(state, getters) {
            return getters.currentProfile.orgUnit.arbetsplatskod;
        },
        currentProfileId(state) {
            return state.currentProfile;
        },
        currentProfile(state) {
            return state.profiles.find((el) => {
                return state.currentProfile === el.pk;
            });
        },
        /**
         * Occupation of the current profile
         */
        occupation(state, getters) {
            return getters.currentProfile.yrkeskategori ? getters.currentProfile.yrkeskategori.displayName : "Annat";
        },
        is_superuser(state, getters) {
            return getters.user.is_superuser;
        },
        is_verksamhetsadmin(state, getters) {
            return getters.user.is_verksamhetsadmin;
        },
        enhetsadministrator(state, getters) {
            return getters.currentProfile.enhetsadministrator;
        },
        profiles(state) {
            return state.profiles;
        },
        user(state) {
            return state.userData;
        },
    },
    actions: {
        loadProfiles({ commit }, { profiles }) {
            commit("setProfiles", profiles);

            if (profiles.length > 0) commit("setUserData", profiles[0].user);
        },
        logout({ commit, rootState }) {
            sessionStorage.removeItem("Refresh");
            sessionStorage.removeItem("Profile");
            sessionStorage.removeItem("AuthenticationMethod");
            sessionStorage.removeItem("Patient");
            setRefreshToken(null);
            setJWT(null);
            setProfile(null);

            let modules = Object.keys(rootState);
            modules.forEach((el) => {
                commit(el + "/reset", null, { root: true });
            });
        },
    },
};
