import { flashMessage, flashWarning, openDialog } from "@/utils";
import dialogEvents from "@/dialogEvents";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import CancellationReceipt from "@/tabs/PrescriptionSet/CancellationReceipt.vue";
import SendReceipt from "@/tabs/PrescriptionSet/SendReceipt.vue";
import Sign from "@/components/Sign.vue";
import { cloneDeep } from "lodash";

//ES5 deep clone of data
const copy = (obj) => JSON.parse(JSON.stringify(obj));

const STATUS_EMPTY = "empty";
const STATUS_MODIFIED = "modified";
const STATUS_SAVING = "saving";
const STATUS_SAVED = "saved";
const STATUS_SENDING = "sending";
const STATUS_SENT = "sent";
const STATUS_SIGNED = "signed";
const STATUS_SIGN_FAILED = "sign failed"; // Bad name, this should be used for signed prescriptionSets where a second signing failed.
const STATUS_SIGNING = "signing";
const STATUS_PRINTING = "printing";
const STATUS_PRINTED = "printed";
const SEND_STATUSES = [STATUS_MODIFIED, STATUS_SAVED, STATUS_SIGNED];
const WORKING_STATUSES = [STATUS_SENDING, STATUS_SAVING, STATUS_SIGNING];

const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
};

// "7350045511119" is the value to use in production. When testing you should override this
// with  VITE_EA_LOC_CODE_RECEPTBREVLADAN=7350045514448 in .env
const EA_LOC_CODE_RECEPTBREVLADAN = import.meta.env.VITE_EA_LOC_CODE_RECEPTBREVLADAN || "7350045511119";

const prescriptionSet = {
    pk: null,
    patient: null,
    prescriptionItemDetails: [],
    authorTime: null,
    signatureTime: null,
    legalAuthenticator: null,
    is_nullified: null,
    is_signed: null,
    nullifiedReason: null,
    accountableHealthcareProfessional: null,
    sent_at: null,
    sent_by: null,
    prescribersComment: null,
    receiver: EA_LOC_CODE_RECEPTBREVLADAN,
    can_send_sms: false,
    patient_has_phonenumber: false,
    patient_onskar_sms: false,
};

const favorit = {
    id: null,
    namn: null,
    anvandare: null,
    favoritrader: [],
};

const valuesChanged = (one, other) => {
    return Object.keys(one).find((key) => {
        if (one[key] && typeof one[key] === "object") {
            return valuesChanged(one[key], other[key]);
        } else {
            if (other) return one[key] !== other[key];
            else return one[key] !== "";
        }
    })
        ? true
        : false;
};

const waitForPoll = async ({ state }) => {
    let failsafeCounter = 300; // Hard timeout of 5 minutes/300 seconds
    while (state.polling && failsafeCounter--) {
        await pollTimeout();
    }
    return new Promise((resolve) => resolve());
};

const pollTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
};

const savePrescriptionSet = async ({ state, getters, commit }, supressFlash = false) => {
    const config = getConfig({}, "Receptförskrivning");

    // Should only allow saving when content has been changed.
    if (state.status !== STATUS_MODIFIED) return;

    // copy state.prescriptionSet to params, since params need to be modified before sending
    let params = getters["basePrescriptionSet"];

    commit("setStatus", STATUS_SAVING);

    // Call either PUT or POST depending on whether state.prescriptionSet.pk is defined or not.
    // State.prescriptionSet.pk will be defined for editing existing recipes, but not for new recipes
    return (
        state.prescriptionSet.pk
            ? klinikenApi.put("/recept/prescriptionsets/" + state.prescriptionSet.pk + "/", params, config)
            : klinikenApi.post("/recept/prescriptionsets/", params, config)
    )
        .then((response) => {
            commit("setPrescriptionSet", response.data);
            commit("setStatus", STATUS_SAVED);

            if (!supressFlash) flashMessage("Ditt recept har sparats.");
        })
        .catch((error) => {
            commit("setStatus", STATUS_MODIFIED);
            openDialog("Misslyckades med att spara recept. Felmeddelande: " + getErrorMessage(error), "error");
        });
};

const signPrescriptionSet = async ({ state, getters, commit }, title = "Skicka beställning till apotek") => {
    const config = getConfig({}, "Receptförskrivning");
    //status at this point can be either SAVED or SIGNED, both are allowed. Store the status, since if signing fails it should revert to initial status.
    let initialStatus = state.status;
    commit("setStatus", STATUS_SIGNING);

    let params = getters["basePrescriptionSet"];

    let url = "/recept/prescriptionsets/";
    if (state.prescriptionSet.pk !== null && state.prescriptionSet.pk !== undefined) {
        url = `${url}${state.prescriptionSet.pk}/`;
    }

    commit("setPolling", true);
    dialogEvents.$emit("openPopup", {
        title: title,
        component: Sign,
        dismissable: false,
        data: {
            params: params,
            config: getConfig({}, "Receptförskrivning"),
            url: url,
            callback: (data) => {
                commit("setPolling", false);
                if (data.is_signed) {
                    commit("setPrescriptionSet", data);
                    commit("setStatus", STATUS_SIGNED);
                }
            },
            errorCallback: () => {
                commit("setPolling", false);
            },
        },
    });

    await waitForPoll({ state });
    // if state at this point is SIGNING, something went wrong. If initialstatus was SIGNED, set to SIGN_FAILED, else to SAVED
    if (state.status === STATUS_SIGNING && initialStatus === STATUS_SIGNED) {
        commit("setStatus", STATUS_SIGN_FAILED);
    } else if (state.status === STATUS_SIGNING) {
        commit("setStatus", STATUS_SAVED);
    }
    return new Promise((resolve) => resolve());
};

const sendPrescriptionSet = async ({ state, commit, dispatch }) => {
    const config = getConfig();
    // Should only allow saving when content has been changed.

    if (state.status !== STATUS_SIGNED) return;

    commit("setStatus", STATUS_SENDING);

    return await klinikenApi
        .post("/recept/prescriptionsets/" + state.prescriptionSet.pk + "/send/", {}, config)
        .then((response) => {
            dispatch("setPrescriptionReceipt", response.data);
        })
        .catch((error) => {
            commit("setStatus", STATUS_SIGNED);
            openDialog("Misslyckades med att skicka recept. Felmeddelande: " + getErrorMessage(error), "error");
        });
};

const printReceptblankettPrescriptionSet = async ({ state, commit }) => {
    // Should only allow saving when content has been changed.
    if (state.status !== STATUS_SIGNED) return;

    commit("setStatus", STATUS_PRINTING);

    const config = getConfig({ responseType: "blob" }, "Receptförskrivning");
    return klinikenApi
        .get(`/recept/prescriptionsets/${state.prescriptionSet.pk}/receptblankett/`, config)
        .then((response) => {
            window.open(URL.createObjectURL(response.data));
            getConfig({}, "Receptförskrivning");
        })
        .catch((error) => {
            commit("setStatus", STATUS_SIGNED);
            openDialog("Misslyckades med att skriva ut recept. Felmeddelande: " + getErrorMessage(error), "error");
        });
};

const signCancellationSet = async ({ state, commit }, { pk, details }) => {
    const config = getConfig({}, "Receptförskrivning");

    commit("setPolling", true);

    let url = "/recept/cancellationsets/";
    dialogEvents.$emit("openPopup", {
        title: "Signera makuleringsbegäran",
        component: Sign,
        data: {
            params: {
                prescriptionSet: pk,
                items: details,
            },
            config: config,
            url: url,
            callback: (data) => {
                commit("setPolling", false);
                commit("setCancellationSet", data);
            },
            errorCallback: () => {
                commit("setPolling", false);
            },
        },
    });

    await waitForPoll({ state });

    return new Promise((resolve) => resolve());
};

const sendCancellationSet = async ({ state, commit, dispatch }) => {
    const config = getConfig({}, "Receptförskrivning");
    return klinikenApi
        .post("/recept/cancellationsets/" + state.cancellationSet.pk + "/send/", state.cancellationSet, config)
        .then((sendResponse) => {
            dispatch("setCancellationReceipt", sendResponse.data);
        })
        .catch((error) => {
            openDialog(
                "Misslyckades med att skicka makuleringsbegäran. Felmeddelande: " + getErrorMessage(error),
                "error"
            );
        })
        .finally(() => {
            commit("setCancellationSet", null);
        });
};

const getDefaultState = () => {
    return {
        status: STATUS_EMPTY,
        prescriptionSet: copy(prescriptionSet),
        favorit: copy(favorit),
        externalPreview: null,
        externalParameters: null,
        cancellationSet: null,
        sessionId: null,
        authenticateServiceKey: null,
        polling: false,
        searchResults: [],
        groupedSearchResults: [],
        previousPrescriptions: [],
        filteredPreviousPrescriptions: [],
        personligaFavoriter: [],
        andrasPersonligaFavoriter: [],
        organisationiskaFavoriter: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setSearchResults(state, searchResults) {
            state.searchResults = searchResults;
        },
        resetFavorit(state) {
            state.favorit = copy(favorit);
        },
        setFavorit(state, favorit) {
            state.favorit = favorit;
        },
        setPreviousPrescriptions(state, previousPrescriptions) {
            state.previousPrescriptions = previousPrescriptions;
        },
        setFilteredPreviousPrescriptions(state, filteredPreviousPrescriptions) {
            state.filteredPreviousPrescriptions = filteredPreviousPrescriptions;
        },
        setPolling(state, polling) {
            state.polling = polling;
        },
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },
        setAuthenticateServiceKey(state, authenticateServiceKey) {
            state.authenticateServiceKey = authenticateServiceKey;
        },
        addItem(state, item) {
            state.prescriptionSet.prescriptionItemDetails.push(item);
            state.status = STATUS_MODIFIED;
        },
        addFavoritItem(state, item) {
            state.favorit.favoritrader.push(item);
        },
        updateItem(state, { item, details }) {
            state.prescriptionSet.prescriptionItemDetails.splice(
                state.prescriptionSet.prescriptionItemDetails.indexOf(item),
                1,
                { ...item, ...details }
            );
            state.status = STATUS_MODIFIED;
        },
        updateFavoritItem(state, { item, details }) {
            state.favorit.favoritrader.splice(state.favorit.favoritrader.indexOf(item), 1, { ...item, ...details });
        },
        removeItem(state, item) {
            state.prescriptionSet.prescriptionItemDetails.splice(
                state.prescriptionSet.prescriptionItemDetails.indexOf(item),
                1
            );
            state.status =
                state.prescriptionSet.prescriptionItemDetails.length || state.prescriptionSet.pk
                    ? STATUS_MODIFIED
                    : STATUS_EMPTY;
        },
        removeFavoritItem(state, item) {
            state.favorit.favoritrader.splice(state.favorit.favoritrader.indexOf(item), 1);
        },
        updateItems(state, items) {
            // Replace items with values from backend, and set new tempId, starting at 1.
            items.forEach((item, i) => (item.tempId = i + 1));
            state.prescriptionSet.prescriptionItemDetails = items;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setReceiverDetails(state, receiverDetails) {
            /**
             * Note: borttagen på begäran av Oscar och Alessandro, eftersom STATUS_MODIDFIED triggar en ny sparning, vilket skapar en ny version.
             * I nuläget oklart om det ska vara så. TODO: kolla upp.
             */
            //if (valuesChanged(receiverDetails, state.prescriptionSet))
            //  state.status = STATUS_MODIFIED;
            for (const key in receiverDetails) {
                let value = receiverDetails[key];
                /*
        if (key === "careCoverageType") {
          state.prescriptionSet.prescriptionItemDetails.forEach((item) => {
            item.careCoverageType = value;
          });
        } else if (key !== "careCoverageTypeDisplay") {*/
                state.prescriptionSet[key] = value;
                //}
            }
        },
        setPk(state, pk) {
            state.prescriptionSet.pk = pk;
        },
        setCancellationSet(state, cancellationSet) {
            state.cancellationSet = cancellationSet;
        },
        setExternalParameters(state, parameters) {
            if (parameters.pk) state.externalPreview = parameters.pk;
            state.externalParameters = parameters;
        },
        setPrescriptionSet(state, prescriptionSet) {
            state.prescriptionSet = prescriptionSet;
        },
        sortItems(state, { key, reversed }) {
            state.prescriptionSet.prescriptionItemDetails.sort((a, b) => {
                const x = a[key].toLowerCase();
                const y = b[key].toLowerCase();
                if (x < y) {
                    return reversed ? 1 : -1;
                }
                if (x > y) {
                    return reversed ? -1 : 1;
                }
                return 0;
            });
        },
        setFavoritNamn(state, namn) {
            state.favorit.namn = namn;
        },
        setFavoritAnvandare(state, anvandare) {
            state.favorit.anvandare = anvandare;
        },
        updatePreviousPrescriptions(state, prescriptionSet) {
            const index = state.previousPrescriptions.findIndex((i) => i.pk === prescriptionSet.pk);

            if (index !== -1) {
                state.previousPrescriptions[index] = prescriptionSet;
            }

            state.previousPrescriptions = [...state.previousPrescriptions];
        },
        updateFilteredPreviousPrescriptions(state, prescriptionSet) {
            const index = state.filteredPreviousPrescriptions.findIndex((i) => i.pk === prescriptionSet.pk);

            if (index !== -1) {
                state.filteredPreviousPrescriptions[index] = prescriptionSet;
            }

            state.filteredPreviousPrescriptions = [...state.filteredPreviousPrescriptions];
        },
        setItemsOnPreviousPrescriptions(state, prescriptionSet) {
            const index = state.previousPrescriptions.findIndex((i) => i.pk === prescriptionSet.pk);

            if (index !== -1) {
                state.previousPrescriptions[index].prescriptionItemDetails = prescriptionSet.prescriptionItemDetails;
            }
        },
        setItemsOnFilteredPreviousPrescriptions(state, prescriptionSet) {
            const index = state.filteredPreviousPrescriptions.findIndex((i) => i.pk === prescriptionSet.pk);

            if (index !== -1) {
                state.filteredPreviousPrescriptions[index].prescriptionItemDetails =
                    prescriptionSet.prescriptionItemDetails;
            }
        },
        setGemensammaaFavoriter: (state, favoriter) => (state.organisationiskaFavoriter = favoriter),
        setPersonligaFavoriter: (state, favoriter) => (state.personligaFavoriter = favoriter),
        setAndrasPersonligaFavoriter: (state, favoriter) => (state.andrasPersonligaFavoriter = favoriter),
    },
    getters: {
        newTempId(state) {
            // Start at 1
            return Math.max(0, ...state.prescriptionSet.prescriptionItemDetails.map((item) => item.tempId || 0)) + 1;
        },
        isPrinted(state) {
            return state.status === STATUS_PRINTED;
        },
        isSendable(state) {
            return SEND_STATUSES.includes(state.status);
        },
        isWorking(state) {
            return WORKING_STATUSES.includes(state.status);
        },
        isModified(state) {
            return state.status === STATUS_MODIFIED;
        },
        items(state) {
            return state.prescriptionSet.prescriptionItemDetails;
        },
        favoritrader(state) {
            return state.favorit.favoritrader;
        },
        favorit(state) {
            return state.favorit;
        },
        pk(state) {
            return state.prescriptionSet.pk;
        },
        externalPreview(state) {
            return state.externalPreview;
        },
        externalParameters(state) {
            return state.externalParameters;
        },
        accountableHealthcareProfessional(state) {
            return state.prescriptionSet.accountableHealthcareProfessional;
        },
        baseFavoritSet(state) {
            let baseFavorit = cloneDeep(state.favorit);

            // if accountableHealthcareProfessional and legalAuthenticator are set, they are likely DetailProfile objects. Convert to pks.
            if (baseFavorit.anvandare && typeof baseFavorit.anvandare === "object")
                baseFavorit.anvandare = baseFavorit.anvandare.pk;

            // Convert all object values in PrescriptionItemDetails to pks (notably indicationForPrescription)
            baseFavorit.favoritrader = baseFavorit.favoritrader.map((item) => {
                item = copy(item);
                for (let [key, value] of Object.entries(item)) {
                    if (value && typeof value === "object") item[key] = value.id;
                }
                if (item.pk === null) delete item.pk;
                if (item.numberOfPackages === 0) delete item.numberOfPackages;
                if (item.dispensingRepeatNumber === 0) delete item.dispensingRepeatNumber;
                delete item.tempId;
                delete item.drugArticle;
                return item;
            });

            return baseFavorit;
        },
        basePrescriptionSet(state, getters, rootState) {
            let basePrescriptionSet = copy(state.prescriptionSet);

            // If patient is not set, use current patient.
            if (!basePrescriptionSet.patient) basePrescriptionSet.patient = rootState.patientData.currentId;
            // if patient is an object, convert to pk/id
            else if (typeof basePrescriptionSet.patient === "object")
                basePrescriptionSet.patient = basePrescriptionSet.patient.id;

            // if accountableHealthcareProfessional and legalAuthenticator are set, they are likely DetailProfile objects. Convert to pks.
            if (
                basePrescriptionSet.accountableHealthcareProfessional &&
                typeof basePrescriptionSet.accountableHealthcareProfessional === "object"
            )
                basePrescriptionSet.accountableHealthcareProfessional =
                    basePrescriptionSet.accountableHealthcareProfessional.pk;
            if (basePrescriptionSet.legalAuthenticator && typeof basePrescriptionSet.legalAuthenticator === "object")
                basePrescriptionSet.legalAuthenticator = basePrescriptionSet.legalAuthenticator.pk;

            if (basePrescriptionSet.edited_by && typeof basePrescriptionSet.edited_by === "object")
                basePrescriptionSet.edited_by = basePrescriptionSet.edited_by.pk;

            if (basePrescriptionSet.created_by && typeof basePrescriptionSet.created_by === "object")
                basePrescriptionSet.created_by = basePrescriptionSet.created_by.pk;

            if (basePrescriptionSet.updated_by && typeof basePrescriptionSet.updated_by === "object")
                basePrescriptionSet.updated_by = basePrescriptionSet.updated_by.pk;

            if (basePrescriptionSet.sent_by && typeof basePrescriptionSet.sent_by === "object")
                basePrescriptionSet.sent_by = basePrescriptionSet.sent_by.pk;

            if (basePrescriptionSet.nullified_by && typeof basePrescriptionSet.nullified_by === "object")
                basePrescriptionSet.nullified_by = basePrescriptionSet.nullified_by.pk;

            if (basePrescriptionSet.printed_by && typeof basePrescriptionSet.printed_by === "object")
                basePrescriptionSet.printed_by = basePrescriptionSet.printed_by.pk;

            // receiver can be either an object or a string
            if (basePrescriptionSet.receiver && typeof basePrescriptionSet.receiver === "object")
                basePrescriptionSet.receiver = basePrescriptionSet.receiver.id;

            // Convert all object values in PrescriptionItemDetails to pks (notably indicationForPrescription)
            basePrescriptionSet.prescriptionItemDetails = basePrescriptionSet.prescriptionItemDetails.map((item) => {
                item = copy(item);
                for (let [key, value] of Object.entries(item)) {
                    if (value && typeof value === "object") item[key] = value.id;
                }
                if (item.pk === null) delete item.pk;
                delete item.tempId;
                return item;
            });

            // prescribersComment is not nullable, delete if null.
            if (!basePrescriptionSet.prescribersComment) delete basePrescriptionSet.prescribersComment;

            return basePrescriptionSet;
        },
        searchResults(state) {
            return state.searchResults;
        },
        groupedSearchResults(state) {
            let groupedResults = [];
            let drugIds = state.searchResults.map((item) => item.drugId).filter(distinct);
            drugIds.forEach((drugId) => {
                groupedResults.push(state.searchResults.filter((item) => item.drugId === drugId));
            });
            return groupedResults;
        },
        previousPrescriptions(state) {
            return state.previousPrescriptions;
        },
        filteredPreviousPrescriptions(state) {
            return state.filteredPreviousPrescriptions;
        },
        prescriptionSet(state) {
            return state.prescriptionSet;
        },
        getGemensammaFavoriter: (state) => state.organisationiskaFavoriter,
        getPersonligaFavoriter: (state) => state.personligaFavoriter,
        getAndrasPersonligaFavoriter: (state) => state.andrasPersonligaFavoriter,
    },
    actions: {
        updateItem({ commit }, { item, details }) {
            if (valuesChanged(details, item)) {
                commit("updateItem", { item, details });
                flashMessage("Beställning ändrad.");
            }
        },
        load({ commit }, prescriptionSet) {
            commit("setPrescriptionSet", copy(prescriptionSet));
            commit("setStatus", STATUS_SAVED);
        },
        addItem({ commit, getters }, { item, details, confirmSilently = false }) {
            item = { ...item, ...details };
            item.tempId = getters.newTempId;
            item.medicinalProduct = item.drugArticle.nplPackId;
            commit("addItem", item);
            if (!confirmSilently) flashMessage("Tillagd i beställning.");
        },
        addFavoritItem({ commit, getters }, { item, details }) {
            item = { ...item, ...details };
            item.tempId = getters.newTempId;
            item.medicinalProduct = item.drugArticle.nplPackId;
            commit("addFavoritItem", item);
            flashMessage("Tillagd i favoritsamling.");
        },
        updateFavoritItem({ commit }, { item, details }) {
            if (valuesChanged(details, item)) {
                commit("updateFavoritItem", { item, details });
                flashMessage("Favorit ändrad.");
            }
        },
        clearOrder({ commit }) {
            // Clear order by overwriting with an empty object.
            commit("setPrescriptionSet", copy(prescriptionSet));
            commit("setStatus", STATUS_EMPTY);
        },
        addMultipleItems({ commit, getters }, items) {
            let allowedItems = 0;
            items.forEach((item) => {
                const newItem = copy(item);
                //delete newItem.pro; // reset "Startförpackning" when copying items.
                newItem.pro = false; // when copying/renewing item, set pro/Startförpackning to false. TODO: Kolla med Elin så att det är okej.
                newItem.pk = null; // reset pk when copying items.
                newItem.tempId = getters.newTempId;
                if (item.medicinalProduct === "SB060301100001")
                    // Special case: this item can not be renewed
                    openDialog(
                        "Licensläkemedel e-förskrivning kan inte förnyas, skapa ny rad från sökrutan istället.",
                        "warning"
                    );
                else if (item.is_withdrawn)
                    // Special case: this item can not be renewed
                    openDialog(
                        "Avregistrerade läkemedel kan inte förnyas, välj ersättningsprodukt från sökrutan istället.",
                        "warning"
                    );
                else {
                    commit("addItem", newItem);
                    allowedItems++;
                }
            });
            if (allowedItems === 1) flashMessage("Tillagd i beställning.");
            else if (allowedItems > 1) flashMessage(allowedItems + " tillagda i beställning.");
        },
        async sendSMSOnPrescription({ commit, getters }, pk) {
            try {
                const response = await klinikenApi.post(
                    "/recept/prescriptionsets/" + pk + "/send-sms/",
                    {},
                    getConfig()
                );
                if (response) {
                    commit("updatePreviousPrescriptions", response.data);
                    commit("updateFilteredPreviousPrescriptions", response.data);
                    flashMessage("Sms har skickats.");
                    return response;
                }
            } catch (error) {
                openDialog("Misslyckades med att skicka sms. Felmeddelande: " + error, "error");
            }
        },
        setPrescriptionReceipt({ commit }, receipt) {
            if (receipt.messageStatus === "0") {
                commit("setStatus", STATUS_SENT);
                commit("setPrescriptionSet", receipt.prescriptionSet);
                flashMessage("Ditt recept har skickats.");
            } else if (receipt.messageStatus === "1") {
                commit("setStatus", STATUS_SENT);
                commit("setPrescriptionSet", receipt.prescriptionSet);
                flashWarning("Ditt recept har accepterats med varningar.");
            } else {
                dialogEvents.$emit("closePopup", "PrescriptionSet.setPrescriptionReceipt()");
                commit("setStatus", STATUS_SIGNED);
                setTimeout(() => {
                    dialogEvents.$emit("openPopup", {
                        title: "Recept kunde inte tas emot",
                        component: SendReceipt,
                        data: receipt,
                    });
                }, 1500);
            }
        },
        setCancellationReceipt({ commit }, receipt) {
            if (receipt.messageStatus === "0" || receipt.messageStatus === "3") {
                commit("setStatus", STATUS_SENT);
                flashMessage("Din makulering är utförd.");
            } else {
                commit("setStatus", STATUS_SAVED);
                setTimeout(() => {
                    dialogEvents.$emit("openPopup", {
                        title: "En eller flera receptrader kunde inte makuleras",
                        component: CancellationReceipt,
                        data: receipt,
                    });
                }, 1500);
            }
        },

        /**
         * Save current favoritSet
         */
        async saveFavoritSet({ commit, getters, state }) {
            let params = getters["baseFavoritSet"];

            // Call either PUT or POST depending on whether state.prescriptionSet.pk is defined or not.
            // State.prescriptionSet.pk will be defined for editing existing recipes, but not for new recipes
            return (
                state.favorit.id
                    ? klinikenApi.put("/recept/favoriter/" + state.favorit.id + "/", params, getConfig())
                    : klinikenApi.post("/recept/favoriter/", params, getConfig())
            )
                .then((response) => {
                    commit("setFavorit", response.data);

                    flashMessage("Din favoritsamling har sparats.");
                })
                .catch((error) => {
                    openDialog(
                        "Misslyckades med att spara favoritsamling. Felmeddelande: " + getErrorMessage(error),
                        "error"
                    );
                });
        },

        async deleteFavoritItem({ commit }, item) {
            try {
                await klinikenApi.delete(`/recept/favoriter/${item.id}/`, getConfig());
            } catch (error) {
                openDialog("Kunde inte radera favoriten", error, "warning");
            }
        },
        /**
         * Save current prescriptionSet
         */
        async save({ commit, getters, state }) {
            // If prescriptionSet is signed, require re-signing
            if (state.prescriptionSet.is_signed) {
                if (state.status !== STATUS_MODIFIED) return;
                else if (state.prescriptionSet.pk) return signPrescriptionSet({ state, getters, commit });
                else return;
            } else return savePrescriptionSet({ state, getters, commit });
        },

        /**
         * Send current prescriptionSet
         */
        async send({ commit, dispatch, getters, state }, details) {
            if (details) await commit("setReceiverDetails", details);

            if (!state.prescriptionSet.is_signed) {
                await savePrescriptionSet({ state, getters, commit }, true);
            }

            if (!getters.isSendable) return;
            else if (!state.prescriptionSet.pk) return;

            await signPrescriptionSet({ state, getters, commit });
            await sendPrescriptionSet({ state, commit, dispatch });

            if (state.status === STATUS_SENT) {
                if (state.prescriptionSet.can_send_sms) {
                    if (state.prescriptionSet.patient_has_phonenumber) {
                        if (state.prescriptionSet.patient_onskar_sms) {
                            dialogEvents.$emit("open", {
                                type: "information",
                                description:
                                    "Vill du skicka en sms-notifiering till patienten att ett recept finns att hämta ut?",
                                buttons: [
                                    {
                                        title: "Nej",
                                        type: "secondary",
                                        action: () => {
                                            dispatch("clearOrder");
                                        },
                                    },
                                    {
                                        title: "Ja",
                                        type: "primary",
                                        action: async () => {
                                            await dispatch("sendSMSOnPrescription", state.prescriptionSet.pk);
                                            dispatch("clearOrder");
                                        },
                                    },
                                ],
                            });
                        } else {
                            dialogEvents.$emit("open", {
                                type: "warning",
                                description: "Sms kan inte skickas eftersom patienten inte önskar det.",
                                buttons: [
                                    {
                                        title: "OK",
                                        type: "primary",
                                        action: () => {
                                            dispatch("clearOrder");
                                        },
                                    },
                                ],
                            });
                        }
                    } else {
                        dialogEvents.$emit("open", {
                            type: "warning",
                            description: "Sms kan inte skickas eftersom mobilnummer till patienten saknas.",
                            buttons: [
                                {
                                    title: "OK",
                                    type: "primary",
                                    action: () => {
                                        dispatch("clearOrder");
                                    },
                                },
                            ],
                        });
                    }
                }
            }

            // This should catch all instances of having completed send() without receiving a receipt _or_ an error, such as during a timeout to eHm.
            if (state.status === STATUS_SENDING) {
                openDialog(
                    "Fick inget svar från E-hälsomyndighetens receptjänst. Var god försök igen senare. Om felet kvarstår, kontakta support.",
                    "error"
                );
                commit("setStatus", STATUS_SIGNED);
            }
        },

        /**
         * print current prescriptionSet
         */
        async printReceptblankett({ commit, dispatch, getters, state }) {
            if (!state.prescriptionSet.is_signed) {
                await savePrescriptionSet({ state, getters, commit });
            }

            await signPrescriptionSet({ state, getters, commit }, "Skriv ut som pappersrecept");

            await printReceptblankettPrescriptionSet({ state, commit, dispatch });

            return state.prescriptionSet.is_signed;
        },

        /**
         * Cancel current prescriptionSet
         */
        async cancel({ commit, dispatch, state, rootState }, { pk, details }) {
            await signCancellationSet({ state, commit }, { pk, details });

            if (!state.cancellationSet.is_signed) return;

            await sendCancellationSet({ state, commit, dispatch });

            dispatch("clearOrder");
        },
        async fetchSearchResults({ commit, dispatch }, searchParams) {
            dispatch("fetchFilteredPreviousPrescriptions", searchParams.q);
            return klinikenApi
                .get("/sil/search/", getConfig({ params: searchParams }))
                .then((response) => {
                    let results = response.data.results;
                    results.forEach((item) => {
                        // TEMPORARY
                        // Remove when API sends in correct case.
                        // item.drugId = item.drugId || item.drugid;
                        // Alternative drugId
                        item.drugId = btoa(item.distributedTradeName.toLowerCase()).replace(/[/=+]/g, "");
                        item.id = item.nplPackId;
                    });
                    commit("setSearchResults", results);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async fetchPreviousPrescriptions({ rootState, commit }) {
            return klinikenApi
                .get("/recept/prescriptionsets/", getConfig({ params: { patient: rootState.patientData.currentId } }))
                .then((response) => {
                    let previousPrescriptions = response.data.results.sort(
                        (a, b) => Date.parse(b.authorTime) - Date.parse(a.authorTime)
                    );
                    commit("setPreviousPrescriptions", previousPrescriptions);
                })
                .catch((e) => {
                    //eslint-disable-next-line
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async fetchFilteredPreviousPrescriptions({ rootState, commit }, q) {
            return klinikenApi
                .get(
                    "/recept/prescriptionsets/",
                    getConfig({ params: { q, patient: rootState.patientData.currentId } }, "Receptförskrivning")
                )
                .then((response) => {
                    let filteredPreviousPrescriptions = response.data.results.sort(
                        (a, b) => Date.parse(b.authorTime) - Date.parse(a.authorTime)
                    );
                    commit("setFilteredPreviousPrescriptions", filteredPreviousPrescriptions);
                })
                .catch((e) => {
                    //eslint-disable-next-line
                    openDialog(getErrorMessage(e), "error");
                });
        },

        async fetchBothFavoriter({ commit }, user) {
            const favoriterURL = "/recept/favoriter/?typ=";
            try {
                const personligaResponse = await klinikenApi.get(favoriterURL + "personliga", getConfig());
                const gemensammaResponse = await klinikenApi.get(favoriterURL + "gemensamma", getConfig());

                if (personligaResponse.status === 200) {
                    commit("setPersonligaFavoriter", personligaResponse.data.results);
                }
                if (gemensammaResponse.status === 200) {
                    commit("setGemensammaaFavoriter", gemensammaResponse.data.results);
                }
                if (user.is_verksamhetsadmin || user.is_superuser) {
                    const andrasPersonligaResponse = await klinikenApi.get(
                        favoriterURL + "andras-personliga",
                        getConfig()
                    );
                    if (andrasPersonligaResponse.status === 200) {
                        commit("setAndrasPersonligaFavoriter", andrasPersonligaResponse.data.results);
                    }
                }
            } catch (error) {
                openDialog("Kunde inte få Favoriter", error);
            }
        },

        async printReceptlista({ commit, dispatch, getters, state }, { ids, patient }) {
            const config = getConfig(
                {
                    responseType: "blob",
                    params: {
                        id: ids.join(","),
                        patient: patient.id,
                    },
                },
                "Receptförskrivning"
            );
            return klinikenApi
                .get(`/recept/prescriptionsets/receptlista/`, config)
                .then((response) => {
                    window.open(URL.createObjectURL(response.data));
                    getConfig({}, "Receptförskrivning");
                })
                .catch((error) => {
                    commit("setStatus", STATUS_SIGNED);
                    openDialog(
                        "Misslyckades med att skriva ut receptlista. Felmeddelande: " + getErrorMessage(error),
                        "error"
                    );
                });
        },
    },
};
