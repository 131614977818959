import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const getDefaultState = () => {
    return {
        systemName: null,
        systemVersion: null,
        kundNamn: "",
        authenticationMethods: [],
        SMSEnabled: false,
        fallbackSignMethod: null,
        ehmGlnReceptbrevladan: null,
        janusBaseURL: null,
        operationXMinuterKvar: 0,
        operationXMinuterKvarEndMarker: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        systemName(state) {
            return state.systemName;
        },
        systemVersion(state) {
            return state.systemVersion;
        },
        kundNamn(state) {
            return state.kundNamn;
        },
        authenticationMethods(state) {
            return state.authenticationMethods;
        },
        SMSEnabled(state) {
            return state.SMSEnabled;
        },
        fallbackSignMethod(state) {
            return state.fallbackSignMethod;
        },
        ehmGlnReceptbrevladan(state) {
            return state.ehmGlnReceptbrevladan;
        },
        janusBaseURL(state) {
            return state.janusBaseURL;
        },
        operationXMinuterKvar(state) {
            return state.operationXMinuterKvar;
        },
        operationXMinuterKvarEndMarker(state) {
            return state.operationXMinuterKvarEndMarker;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        load(state, data) {
            state.systemName = data.system_name;
            state.systemVersion = data.system_version;
            state.kundNamn = data.kundnamn;
            state.authenticationMethods = data.authentication_methods;
            state.SMSEnabled = data.sms_enabled;
            state.fallbackSignMethod = data.fallback_sign_method;
            state.ehmGlnReceptbrevladan = data.ehm_gln_receptbrevladan;
            state.janusBaseURL = data.janus_base_url;
            state.operationXMinuterKvar = data.operation_x_minuter_kvar;
            state.operationXMinuterKvarEndMarker = data.operation_x_minuter_kvar_end_marker;
        },
    },
    actions: {
        async loadFromBackend({ commit }, failSilently = false) {
            const config = getConfig();
            return klinikenApi
                .get("systeminformation/", config)
                .then((response) => {
                    commit("load", response.data);
                })
                .catch((e) => {
                    if (!failSilently) {
                        openDialog("Kunde inte systeminformation från servern." + getErrorMessage(e), "warning");
                    }
                });
        },
    },
};
