//import $ from 'jquery';

import { flashMessage, openDialog } from "@/utils";
import dialogEvents from "@/dialogEvents";
import { getConfig, getErrorMessage, klinikenApi, klinikenApiNoTimeout } from "@/api";
import Sign from "@/components/Sign.vue";
import { cloneDeep } from "lodash";

const STATUS_EMPTY = "empty";
const STATUS_MODIFIED = "modified";
const STATUS_SAVING = "saving";
const STATUS_SAVED = "saved";
const STATUS_SIGNED = "signed";
const STATUS_SIGNING = "signing";
const STATUS_SIGN_FAILED = "sign failed";

const SAVE_STATUSES = [STATUS_EMPTY, STATUS_MODIFIED, STATUS_SAVED];
const SIGN_STATUSES = [STATUS_EMPTY, STATUS_MODIFIED, STATUS_SAVED, STATUS_SIGNED];
const WORKING_STATUSES = [STATUS_SIGNING, STATUS_SAVING];

//ES5 deep clone of data
const copy = (obj) => JSON.parse(JSON.stringify(obj));

const waitForPoll = async ({ state }) => {
    let failsafeCounter = 300; // Hard timeout of 5 minutes/300 seconds
    while (state.polling && failsafeCounter--) {
        await pollTimeout();
    }

    return new Promise((resolve) => resolve());
};

const pollTimeout = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
};

const cleanSektion = function (sektion) {
    if (sektion.sokord && typeof sektion.sokord === "object") sektion.sokord = sektion.sokord.pk;
    if (sektion.sokordsstruktur && typeof sektion.sokordsstruktur === "object")
        sektion.sokordsstruktur = sektion.sokordsstruktur.pk;
    if (sektion.textObjectModel && sektion.textObjectModel.code && sektion.textObjectModel.displayName) {
        sektion.text = sektion.textObjectModel.code + " " + sektion.textObjectModel.displayName;
    }
    if (typeof sektion.text !== "string") sektion.text = String(sektion.text);
    if (sektion.sektioner)
        for (let i = 0; i < sektion.sektioner.length; i++) {
            cleanSektion(sektion.sektioner[i]);
        }
};

const saveAnteckning = async ({ state, getters, commit }) => {
    const config = getConfig({}, "Journalanteckningar");
    // Should only allow saving when content has been changed.
    if (state.status !== STATUS_MODIFIED) return;

    let data = getters["baseAnteckning"];

    commit("setStatus", STATUS_SAVING);

    return (
        state.anteckning.pk
            ? klinikenApiNoTimeout.put("/anteckning/journalanteckningar/" + state.anteckning.pk + "/", data, config)
            : klinikenApiNoTimeout.post("/anteckning/journalanteckningar/", data, config)
    )
        .then((response) => {
            commit("setAnteckning", response.data); // Overwrite anteckning in state with new anteckning object from Frontend so that we for instance get UUIDs of sektioner
            commit("setStatus", STATUS_SAVED);
            flashMessage("Din anteckning har sparats.");
        })
        .catch((error) => {
            commit("setStatus", STATUS_MODIFIED);
            openDialog("Misslyckades med att spara anteckning. Felmeddelande: " + getErrorMessage(error), "error");
        });
};

const signAnteckning = async ({ state, getters, commit }) => {
    const config = getConfig({}, "Journalanteckningar");

    //status at this point can be either SAVED or SIGNED, both are allowed. Store the status, since if signing fails it should revert to initial status.
    let initialStatus = state.status;
    commit("setStatus", STATUS_SIGNING);

    let params = getters["baseAnteckning"];

    let url = "/anteckning/journalanteckningar/";
    if (state.anteckning.pk !== null && state.anteckning.pk !== undefined) {
        url = `${url}${state.anteckning.pk}/`;
    }

    commit("setPolling", true);
    dialogEvents.$emit("openPopup", {
        title: "Signera journalanteckning",
        component: Sign,
        dismissable: false,
        data: {
            config: config,
            params: params,
            url: url,
            callback: (data) => {
                commit("setPolling", false);
                if (data.is_signed) {
                    commit("setAnteckning", data);
                    commit("setStatus", STATUS_SIGNED);
                }
            },
            errorCallback: () => {
                commit("setPolling", false);
            },
        },
    });

    await waitForPoll({ state });
    // if state at this point is SIGNING, something went wrong. If initialstatus was SIGNED, set to SIGN_FAILED, else to SAVED
    if (state.status === STATUS_SIGNING && initialStatus === STATUS_SIGNED) commit("setStatus", STATUS_SIGN_FAILED);
    else if (state.status === STATUS_SIGNING) commit("setStatus", STATUS_SAVED);
    return new Promise((resolve) => resolve());
};

const anteckning = {
    pk: null,
    patient: null,
    sektioner: [],
    authorTime: null,
    signatureTime: null,
    accountableHealthcareProfessional: null,
    is_nullified: null,
    nullifiedReason: null,
    is_signed: null,
};

const getDefaultState = () => {
    return {
        currentId: null,
        status: STATUS_EMPTY,
        anteckning: copy(anteckning),
        externalPreview: null, // Will be used in "uthopp" to go directly to an anteckning
        cancellationPk: null, // Will be used when cancelling anteckningar
        journalTyper: [],
        /**
         * Data needed when signing an anteckning using SITHS or BankID
         */
        sessionId: null,
        polling: false,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setPolling(state, polling) {
            state.polling = polling;
        },
        setAnteckning(state, anteckning) {
            state.anteckning = anteckning;
        },
        updateAnteckning(state, anteckning) {
            delete anteckning.pk;
            state.anteckning = { ...state.anteckning, ...anteckning };
            state.anteckning.sektioner = anteckning.sektioner;
            state.status = STATUS_MODIFIED;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setCurrentId(state, id) {
            state.currentId = id;
        },
        setExternalPreview(state, pk) {
            state.externalPreview = pk;
        },
        setJournalTyper(state, journalTyper) {
            state.journalTyper = journalTyper;
        },
    },
    getters: {
        baseAnteckning(state, getters, rootState) {
            let baseAnteckning = copy(state.anteckning);
            cleanSektion(baseAnteckning);

            if (baseAnteckning.anteckningstyp && typeof baseAnteckning.anteckningstyp === "object")
                baseAnteckning.anteckningstyp = baseAnteckning.anteckningstyp.pk;

            // If patient is not set, use current patient.
            if (!baseAnteckning.patient || typeof baseAnteckning.patient === "object")
                baseAnteckning.patient = rootState.patientData.currentId;

            // if legalAuthenticator are set, they are likely DetailProfile objects. Convert to pks.
            if (baseAnteckning.legalAuthenticator && typeof baseAnteckning.legalAuthenticator === "object")
                baseAnteckning.legalAuthenticator = baseAnteckning.legalAuthenticator.pk;

            // if accountableHealthcareProfessional are set, they are likely DetailProfile objects. Convert to pks.
            if (
                baseAnteckning.accountableHealthcareProfessional &&
                typeof baseAnteckning.accountableHealthcareProfessional === "object"
            )
                baseAnteckning.accountableHealthcareProfessional = baseAnteckning.accountableHealthcareProfessional.pk;

            return baseAnteckning;
        },
        anteckning(state) {
            return state.anteckning;
        },
        isSaveable(state) {
            return SAVE_STATUSES.includes(state.status) && !state.anteckning.is_signed;
        },
        isSignable(state) {
            return SIGN_STATUSES.includes(state.status);
        },
        isWorking(state) {
            return WORKING_STATUSES.includes(state.status);
        },
        isModified(state) {
            return state.status === STATUS_MODIFIED;
        },
        status(state) {
            return state.status;
        },
        externalPreview(state) {
            return state.externalPreview;
        },
        journalTyper(state) {
            return state.journalTyper;
        },
    },
    actions: {
        clearAnteckning({ commit }) {
            // Clear order by overwriting with an empty object.
            commit("setAnteckning", copy(anteckning));
            commit("setStatus", STATUS_EMPTY);
        },
        async loadJournalTyper({ commit }) {
            const config = getConfig({}, "Journalanteckningar");
            return klinikenApi
                .get("/anteckning/typer/", config)
                .then((response) => {
                    commit("setJournalTyper", response.data.results);
                })
                .catch((e) => {
                    openDialog("Kunde inte uppdatera journaltyper från servern. " + e, "warning");
                });
        },
        async getJournalanteckning({ commit }, id) {
            let response = await klinikenApi.get(
                "/anteckning/journalanteckningar/" + id + "/",
                getConfig({}, "Journalanteckningar")
            );
            commit("setAnteckning", response.data);
        },

        async save({ commit, getters, state }) {
            // If prescriptionSet is signed, require re-signing
            if (state.anteckning.is_signed) {
                return;
            } else return saveAnteckning({ state, getters, commit });
        },
        //eslint-disable-next-line
        async makuleraAnteckning({ commit }, { parameters }) {
            let anteckning = cloneDeep(parameters.anteckning);
            anteckning.nullifiedReason = parameters.nullifiedReason;
            for (let i = 0; i < anteckning.sektioner.length; i++) {
                cleanSektion(anteckning.sektioner[i]);
            }

            await klinikenApi
                .put(
                    "/anteckning/journalanteckningar/" + anteckning.pk + "/nullify/",
                    anteckning,
                    getConfig({}, "Journalanteckningar")
                )
                .then((response) => {
                    commit("setAnteckning", response.data); // Overwrite anteckning in state with new anteckning object from Frontend so that we for instance get UUIDs of sektioner
                    flashMessage("Anteckning makulerad.");
                    dialogEvents.$emit("closePopup");
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async sign({ commit, getters, state }) {
            return signAnteckning({ state, getters, commit });
        },
    },
};
