import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { openDialog } from "@/utils";

const getDefaultState = () => {
    return {
        orgUnits: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        orgUnits(state) {
            return state.orgUnits;
        },
        orgUnit(state) {
            return state.orgUnit;
        },
    },
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setOrgUnits: (state, data) => {
            state.orgUnits = data;
        },
    },
    actions: {
        async save(context, data) {
            try {
                const response = await klinikenApi.post(`core/orgunits/`, data.orgUnit, getConfig());
                return response;
            } catch (e) {
                openDialog("Kunde inte spara enhet: " + getErrorMessage(e), "warning");
            }
        },
        async update(context, data) {
            try {
                const response = await klinikenApi.put(`core/orgunits/${data.orgUnit.id}/`, data.orgUnit, getConfig());
                // eslint-disable-next-line
                return response;
            } catch (e) {
                openDialog("Kunde inte uppdatera enheten: " + getErrorMessage(e), "warning");
            }
        },
        async load({ commit }, failSilently = false) {
            try {
                const response = await klinikenApi.get(`core/orgunits/`, getConfig());
                let orgUnits = response.data.results;
                commit("setOrgUnits", orgUnits);
            } catch (e) {
                if (!failSilently) {
                    openDialog("Kunde inte hämta uppgifter om enheten." + getErrorMessage(e), "warning");
                }
            }
        },
    },
};
