<template>
    <div id="confirm-overlay" :class="{ open: active, flash: isFlash }" @click="dismiss">
        <div v-show="!isFlash" class="dialog" role="dialog" :class="options.type">
            <div class="head" v-show="options.type !== 'flash'">
                <figure></figure>
            </div>
            <div class="body">
                <h2>{{ options.description }}</h2>
                <h2 v-for="(error, index) in options.errorList" :key="index">
                    {{ error }}
                </h2>
                <div class="btn-group">
                    <button
                        v-for="(button, index) in options.buttons"
                        :key="index"
                        @click="doAction(button.action)"
                        class="btn"
                        :class="'btn-' + button.type"
                    >
                        {{ button.title }}
                    </button>
                </div>
            </div>
        </div>
        <div v-show="isFlash" class="flash" role="dialog">
            <h2>
                <img v-if="options.type === 'flashwarning'" src="@/assets/dialogs/flash_warning.svg" alt="" />
                <img v-else src="@/assets/dialogs/flash.svg" alt="" />
                {{ options.description }}
            </h2>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import dialogEvents from "@/dialogEvents";
import emitter from "tiny-emitter/instance";
import FocusControlMixin from "@/mixins/FocusControlMixin";

const DEFAULT_OPTIONS = {
    type: "warning",
    description: "These are not the droids you're looking for.",
    dismissable: true,
    buttons: [
        {
            title: "OK",
            type: "primary",
        },
    ],
};

export default {
    data() {
        return {
            options: {},
            active: false,
            queue: [],
        };
    },
    methods: {
        open(options) {
            if (this.active) this.queue.push(options);
            else this._open(options);
        },
        _open(options) {
            this.setOptions(options);
            this.active = true;
            if (this.isFlash) {
                setTimeout(() => {
                    $(this.$el).fadeOut("fast", this.close);
                }, this.options.timeout);
            } else {
                $("body").addClass("takeover");
            }
        },
        setOptions(options) {
            this.options = $.extend({}, DEFAULT_OPTIONS, options || {});
        },
        close() {
            // Reset style added from jQuery fadeOut.
            $(this.$el).removeAttr("style");
            if (this.queue.length) this._open(this.queue.shift());
            else {
                $("body").removeClass("takeover");
                this.active = false;
                this.options = {};
            }
        },
        doAction(action) {
            if (typeof action === "function") {
                action();
            }
            this.close();
        },
        dismiss(event) {
            if ((this.options.dismissable && !(event instanceof MouseEvent)) || event.target === this.$el) {
                this.close();
            }
        },
    },
    created() {
        // FIXME: dialogEvents kommer sluta fungera iom migrationen till Vue 3, emitter fungerar på samma sätt men är kompatibel med Vue 3.
        // Byt ut dialogEvents.$emit("") överallt där det används mot emitter.emit("") (utan $).
        dialogEvents.$on("open", this.open);
        emitter.on("open-dialog", this.open);
    },
    computed: {
        isFlash() {
            return this.options.type === "flash" || this.options.type === "flashwarning";
        },
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/variables"


/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/buttons"
@import "bootstrap/scss/modal"
/* Bootstrap - End */

@import "@/style/deprecated_main"


body.takeover
  overflow: hidden

#confirm-overlay
  z-index: 1040
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: none
  background-color: rgba(#000, 0)
  transition: background-color .2s

  body.takeover &
    display: block
    background-color: rgba(#000, .25)

  &.flash
    display: block
    width: auto
    height: auto
    top: 50%
    left: 50%
    bottom: auto
    right: auto
    transform: translate(-50%, -50%)
    background-color: rgba(#000, .8)
    color: #fff
    border-radius: .3rem
    padding: 1rem 3rem

  .dialog
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 80vw
    min-width: 320px
    max-width: 540px
    box-shadow: 10px 10px 40px -12px rgba(#000, .5)

    .head
      background-color: $dialog-alert
      height: 140px
      text-align: center
      padding-top: 20px

      figure
        display: inline-block
        width: 100px
        height: 100px
        border-radius: 50%
        background-color: #fff
        background-image: url('@/assets/dialogs/default.svg')
        background-size: 40px 40px
        background-repeat: no-repeat
        background-position: center center

    .body
      background-color: #fff
      padding: 2rem
      overflow: auto

      h2
        margin: 20px
        max-height: calc(100vh - 400px)
        color: $color-text-popup

    .btn-group
      float: right

      .btn-secondary
        border: 1px solid $color-active-dark
        background-color: #ffffff
        color: $color-active-dark

    &.warning
      .head
        background-color: $dialog-warning

        figure
          background-image: url('@/assets/dialogs/warning.svg')
          background-size: 60px 60px

    &.error
      .head
        background-color: $dialog-error

        figure
          background-image: url('@/assets/dialogs/error.svg')
          background-size: 35px 35px

    &.information
      .head
        background-color: $dialog-information

        figure
          background-image: url('@/assets/dialogs/information.svg')
          background-size: 35px 35px

  .flash
    width: auto

    h2
      margin: 0
      color: #FFFFFF

      img
        vertical-align: -5px
        margin: 0 13px 0 -13px
</style>
