<template>
    <div class="range">
        <!--
            Problem: IE10 does not support spinners in input type number at all, not even custom CSS.
            If value changes due to using spinners, this will _not_ trigger onchange or oninput.
            Added code to emit onclick with event.target.value set on spinner use.
            Listening to spinner clicks in parent can work as well, but the DOM parent/child relationship
            between the buttons and the input must be fixed first or everything given unique names.
            This code is more flexible but less elegant.
            TODO: replace anonymous custom object with CustomEvent()?
        -->
        <template v-if="!preview">
            <input
                type="number"
                :min="min"
                :max="max"
                :name="name"
                :step="step"
                v-model.number="value_"
                v-bind="$attrs"
                @blur="$emit('blur')"
                @keydown.down="decrease()"
                @keydown.up="increase()"
                @change="validateRange()"
                @keydown="inputTypeNumber($event)"
            />
            <span class="increase-decrease">
                <button type="button" @click="increase" tabindex="-1"></button>
                <button type="button" @click="decrease" tabindex="-1"></button>
            </span>
        </template>
        <span v-else class="input-preview">
            {{ value_ }}
        </span>
    </div>
</template>
<script>
export default {
    name: "NumberWidget",
    props: {
        name: String,
        min: Number,
        max: Number,
        step: {
            type: Number,
            default: 1,
        },
        value: undefined, // Can be String or Number
        preview: Boolean,
    },
    data() {
        return {
            value_: isNaN(parseFloat(this.value)) ? undefined : parseFloat(this.value),
        };
    },
    computed: {
        stepDecimals() {
            return this.step % 1 ? this.step.toString().split(".")[1].length : 0;
        },
    },
    methods: {
        increase() {
            if (this.value_ == "" || isNaN(this.value_)) {
                this.value_ = 0;
            }
            this.value_ += this.step;
            this.validateRange();
        },
        decrease() {
            if (this.value_ == "" || isNaN(this.value_)) this.value_ = 0;
            this.value_ -= this.step;
            this.validateRange();
        },

        /*
            Emulate number field in IE10, not necessary in Chrome.
            IE10 removes illegal characters on blur, this will prevent their input like Chrome.
        */
        inputTypeNumber(event) {
            var keyChar = event.charCode || event.keyCode;
            if (
                keyChar == 13 || //enter
                keyChar == 9 || //tab
                keyChar == 8 || //delete
                keyChar == 46 || //backspace
                keyChar == 37 || //left arrow
                keyChar == 39 || //right arrow
                keyChar == 189 // -
            )
                return true;
            else if (keyChar == 188 || keyChar == 190) {
                // if '.' or ','
                if (this.stepDecimals === 0)
                    // check for whole number
                    event.preventDefault();
                else return true;
            } else if (keyChar >= 48 || keyChar <= 57) {
                return true;
            } else if (keyChar >= 96 || keyChar <= 105) {
                return true;
            } else {
                event.preventDefault();
            }
        },
        validateRange() {
            this.value_ = parseFloat(this.value_);

            if (isNaN(this.value_)) {
                this.value_ = "";
                this.$emit("input", this.value_);
                return true;
            }

            if (this.value_ < this.min) this.value_ = this.min;
            if (this.value_ > this.max) this.value_ = this.max;

            // TODO: add check to only allow multiples of step_
            var intermediateValue = this.value_;
            if (this.stepDecimals > 0) intermediateValue = parseFloat(intermediateValue.toFixed(this.stepDecimals));
            this.value_ = intermediateValue;

            this.$emit("input", this.value_);
        },
    },
    watch: {
        min() {
            this.validateRange();
        },
        max() {
            this.validateRange();
        },
        value() {
            this.value_ = this.value;
            this.validateRange();
        },
    },
};
</script>

<style lang="sass" scoped>
@import "@/style/variables"

/* Bootstrap - Start */
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"

@import "bootstrap/scss/type"
@import "bootstrap/scss/forms"
@import "bootstrap/scss/buttons"
@import "bootstrap/scss/button-group"
@import "bootstrap/scss/input-group"
@import "bootstrap/scss/custom-forms"
/* Bootstrap - End */

@import "@/style/deprecated_main"
.range
    display: flex

    input
        border-top-right-radius: 0px !important
        border-bottom-right-radius: 0px !important
        border-right: none

    .increase-decrease
        display: flex
        flex-direction: column
        background-image: url('@/assets/plus_minus.svg')
        border: 1px solid #95A1B1
        border-left: none
        border-bottom-right-radius: 4px !important
        border-top-right-radius: 4px !important
        background-color: #FFFFFF

    button
        opacity: 0
        //background-color: white
        //border: 1px solid #95A1B1
        //border-left: none
        height: 22px
        width: 22px
        flex: 0 0 auto
        border: none
        //&:first-of-type
        //    border-top-right-radius: 4px !important
        //&:last-of-type
        //    border-bottom-right-radius: 4px !important
        //    border-top: 0px

//Supposed to only target IE10, but unfortunately targets IE11 as well.
@media screen and (min-width:0/0)
    .range
        margin-right: 22px

// Hide spinners in Firefox
input[type=number]
    -moz-appearance: textfield

// Hide spinners in other browsers
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
</style>
