<template>
    <div class="expand-flex">
        <div class="expand-flex-main">
            <suggest-widget
                :preview="preview"
                v-for="(value, index) in values"
                :key="name + '-' + index"
                :id="name + '-' + index"
                v-bind="$attrs"
                v-model="values[index]"
                :apisearch="apisearch"
                :extraParams="extraParams"
                :name="name"
                :required="required"
                :map="map"
                @blur="$emit('blur')"
            />
        </div>
        <div class="expand-flex-side" v-if="!preview">
            <div
                class="side-button-wrapper"
                :class="{
                    disabled: this.hasValues() !== this.count || (this.maxfields && this.count >= this.maxfields),
                }"
            >
                <button
                    type="button"
                    :disabled="this.hasValues() !== this.count || (this.maxfields && this.count >= this.maxfields)"
                    class="side-button"
                    v-on:click="openNew()"
                >
                    <div class="image-wrapper">+</div>
                    Lägg till {{ displayName ? displayName : name }}
                </button>
            </div>
            <div
                class="side-button-wrapper"
                v-for="(value, index) in values"
                :key="name + '-img-' + index"
                :style="index === 0 ? 'display: none' : ''"
            >
                <button type="button" @click="removeItem(index)" class="side-button">
                    <img src="@/assets/delete.svg" />Radera fält
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SuggestWidget from "@/components/widgets/inputs/SuggestWidget.vue";

export default {
    name: "ExpandableSuggest",
    components: {
        SuggestWidget,
    },
    props: {
        apisearch: String,
        name: String,
        displayName: String,
        required: Boolean,
        maxlength: String,
        value: undefined,
        map: Function,
        maxfields: Number,
        extraParams: Object,
        preview: Boolean,
        disabled: Boolean,
    },
    data: function () {
        return {
            values: Array.isArray(this.value) && this.value.length ? this.value : [""],
            objectValues: [],
            count: 1,
        };
    },
    methods: {
        hasValues() {
            const newArr = this.values.filter((item) => item != "");
            return newArr.length;
        },
        async openNew(value = "") {
            this.values.push(value);
            this.count += 1;
            const input = await this.focusInput();
            input.focus();
        },
        async focusInput() {
            let string = `#${this.name}-${this.hasValues()}`;
            await this.$nextTick();
            const listOfEl = document.querySelectorAll(string);
            await this.$nextTick();
            return listOfEl[1];
        },
        removeItem(index) {
            document.activeElement.blur();
            if (this.values.length > 1) {
                this.values.splice(index, 1);
            }
            this.count -= 1;
        },
        updateAndEmitObjectValues(event, index) {
            this.objectValues[index] = event;
            this.$emit("update", this.objectValues);
        },
    },
    watch: {
        value() {
            this.values = this.value.length ? this.value : [""];
        },
        values() {
            this.$emit("input", this.values);
        },
    },
};
</script>

<style lang="sass" scoped>
.expand-flex
    display: flex

.expand-flex-main
    flex-grow: 1

    div:not(:last-of-type)
        margin-bottom: 10px

.expand-flex-side
    flex-grow: 0

.side-button-wrapper
    height: 46px
    line-height: 46px
    display: block
    padding-top: 2px
    padding-bottom: 12px
    padding-left: 10px

    &:not(:last-of-type)
        margin-bottom: 10px

    &:first-of-type
        padding-left: 20px

    .side-button
        border: none
        white-space: nowrap
        font-family: Roboto Medium
        color: black
        border-radius: 4px
        background-color: inherit
        height: 38px
        line-height: 32px

        &:hover:not([disabled])
            background-color: #CEE9F3

        .image-wrapper
            display: inline-block
            width: 28px
            height: 28px
            line-height: 28px
            border-radius: 14px
            background-color: #38A7CF
            color: white
            margin-right: 8px

        img
            margin-top: -6px
            margin-right: 8px

.disabled
    opacity: 0.3
</style>
