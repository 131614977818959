import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { flashMessage, openDialog } from "@/utils";
import dialogEvents from "@/dialogEvents";

const getDefaultState = () => {
    return {
        currentId: null,
        patientData: {},
        patientDataHidden: false,
        editPatientId: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        load(state, patient) {
            state.currentId = patient.id;
            state.patientData = patient;
            sessionStorage.setItem("Patient", state.currentId);
        },
        hidePatientData(state, hidden) {
            state.patientDataHidden = hidden;
        },
        setEditPatient(state, id) {
            state.editPatientId = id;
        },
    },
    /**
     *  Lots of failsafes during testing.
     */
    getters: {
        /*
            TODO: check for id type. Reservnummer usually has format YYYYMMDDXXXX, but is all kinds of arbitrary with letters as well as numbers.
            A simple check for ID type "1.2.752.129.2.1.3.1" should suffice, everything else is reservnummer.
            Note that DOB is *not* always the first 6 numbers of personnummer and DOB should always be used.
        */

        /**
         *  Experimental code to return custom table getters for sensitive information.
         */
        personIdGetter(state) {
            if (!state.patientDataHidden)
                return (personId) => {
                    return personId;
                };
            else
                return (personId) => {
                    return personId ? personId.slice(0, 4) + "XXXX-XXXX" : null;
                };
        },
        nameGetter(state) {
            if (!state.patientDataHidden)
                return (fornamn, efternamn) => {
                    return fornamn && efternamn ? fornamn + " " + efternamn : null;
                };
            // new String(fornamn / efternamn) necessary since fornamn can be null.
            else
                return (fornamn, efternamn) => {
                    return fornamn && efternamn ? fornamn.charAt(0) + "*** " + efternamn.charAt(0) + "***" : null;
                };
        },
        personId(state, getters) {
            return getters.personIdGetter(state.patientData.personId);
        },
        careCoverage(state) {
            return state.patientData.careCoverage;
        },
        name(state, getters) {
            return getters.nameGetter(state.patientData.fornamn, state.patientData.efternamn);
        },
        gender(state, getters) {
            if (state.patientData.gender != null)
                if (state.patientData.gender.displayName != null) return state.patientData.gender.displayName;
            return getters.binaryGender;
        },
        binaryGender(state) {
            if (state.currentId) return parseInt(state.patientData.personId.charAt(10)) % 2 === 0 ? "Kvinna" : "Man";
            else return "";
        },
        address(state) {
            return (
                (!state.coAdress
                    ? (state.patientData.adressRad1 + " " || "") + (state.patientData.adressRad2 + " " || "")
                    : state.coAdress + " ") +
                state.patientData.postnummer +
                " " +
                state.patientData.postort
            );
        },
        dateOfBirth(state) {
            if (state.currentId) return state.patientData.dateOfBirth || state.patientData.personId.slice(0, 8);
            else return "";
        },
        editPatient(state) {
            return state.editPatientId;
        },
        currentId(state) {
            return state.currentId;
        },
        patient(state) {
            return state.patientData;
        },
    },
    actions: {
        _loadFromBackend({ commit, dispatch }, { id = "current", failSilently = false }) {
            dispatch("tabs/closeAllPatientBound", null, { root: true });

            return klinikenApi
                .get("core/patienter/" + id + "/", getConfig())
                .then((response) => {
                    commit("load", response.data);
                    flashMessage("Aktiv patient: " + response.data.personId);
                })
                .catch((e) => {
                    if (!failSilently) {
                        openDialog("Kunde inte hämta patient från servern." + getErrorMessage(e), "warning");
                    }
                });
        },
        loadFromBackend({ dispatch, state, rootGetters }, { id = "current", failSilently = false }) {
            if (state.currentId !== id) {
                let hasUnsavedData = false;

                let tabId = undefined;
                rootGetters["tabs/tabs"].forEach((tab) => {
                    if (tab.patientBound) {
                        hasUnsavedData = tab.hasUnsavedData ? true : hasUnsavedData;
                        tabId = tab.id;
                    }
                });

                if (hasUnsavedData) {
                    dialogEvents.$emit("open", {
                        type: "warning",
                        description: "Du har osparad data för tidigare vald patient. Vill du gå vidare utan att spara?",
                        buttons: [
                            {
                                title: "Avbryt",
                                type: "secondary",
                                action: () => {
                                    dispatch("tabs/showTab", { id: tabId }, { root: true });
                                },
                            },
                            {
                                title: "Ja, gå vidare utan att spara",
                                type: "primary",
                                action: () => {
                                    dispatch("_loadFromBackend", { id, failSilently });
                                },
                            },
                        ],
                    });
                } else {
                    dispatch("_loadFromBackend", { id, failSilently });
                }
            }
        },
    },
};
