<template>
    <div class="anvandare">
        <form @submit.prevent="submit" id="user-registration-details">
            <admin-lock-border />

            <h2 v-if="!userData.pk">Skapa ny användare</h2>
            <h2 v-else>Redigera användare</h2>
            <span class="margin-left fixed-width"
                >Kopplat till varje användare och profil finns behörigheter. För att avgöra vilka behörigheter
                användaren bör ha är det viktigt att tänka på både patientintegritet och patientsäkerhet.</span
            >

            <div class="form-group k3-form fullscreen">
                <div class="k3-sub-form-flex-container">
                    <div>
                        <div class="circle">
                            <div class="number">1</div>
                        </div>
                        <div class="vr"></div>
                    </div>
                    <div>
                        <div class="left-pad" @click="toggleVisible('user')">
                            <h3>
                                Användare<img
                                    :class="['k3-dropdown-icon', !hidden.user ? 'k3-img-rotate' : '']"
                                    src="@/assets/dropdown-arrow-blue.svg"
                                />
                            </h3>
                        </div>
                        <collapse-transition>
                            <div class="k3-sub-form" v-if="!hidden.user">
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="Förnamn"
                                    name="first_name"
                                    v-model="userData.first_name"
                                    :maxlength="30"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="Efternamn"
                                    name="last_name"
                                    v-model="userData.last_name"
                                    :maxlength="150"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    :disabled="editSelf"
                                    label="Användarnamn"
                                    name="username"
                                    v-model="userData.username"
                                    :required="true"
                                    :maxlength="150"
                                    pattern="^[\w.@+-]+$"
                                />
                                <div class="colspan c2">&nbsp;</div>
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    :disabled="editSelf"
                                    label="PersonID"
                                    name="personId"
                                    v-model="userData.personId"
                                    :maxlength="24"
                                />
                                <input-wrapper
                                    :component="widgets.RadioButtonWidget"
                                    :disabled="editSelf || editOwnUser"
                                    name="user_is_active"
                                    :colspan="2"
                                    v-model="userData.is_active"
                                    :options="[{ text: 'Aktiv användare', value: true }]"
                                />
                                <input-wrapper
                                    :component="widgets.RadioButtonWidget"
                                    :disabled="editSelf || editOwnUser"
                                    name="user_is_active"
                                    :colspan="2"
                                    v-model="userData.is_active"
                                    :options="[{ text: 'Inaktiv användare', value: false }]"
                                />
                                <div class="colspan c2">&nbsp;</div>
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="E-postadress"
                                    name="email"
                                    v-model="userData.email"
                                    :maxlength="254"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="Telefonnummer"
                                    name="telephoneNumber"
                                    v-model="userData.telephoneNumber"
                                    :maxlength="20"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="Mobiltelefonnummer"
                                    name="mobileTelephoneNumber"
                                    v-model="userData.mobileTelephoneNumber"
                                    :maxlength="20"
                                />
                                <div class="colspan c2">&nbsp;</div>
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    :colspan="4"
                                    label="HSA-utfärdare"
                                    name="hsautfardare"
                                    information="Krävs om inloggning sker med SITHS-kort"
                                    v-model="userData.hsautfardare"
                                    apisearch="/core/kodverk/hsautfardare/"
                                    :map="
                                        (el) => {
                                            return { text: el.displayName + ' (' + el.code + ')', value: el.code };
                                        }
                                    "
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    :disabled="editSelf"
                                    label="HSA-nummer"
                                    name="hsalopnummer"
                                    v-model="userData.hsalopnummer"
                                    :maxlength="24"
                                />
                                <div class="colspan c2">&nbsp;</div>
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="true"
                                    label="Superuser"
                                    name="is_superuser"
                                    v-model="userData.is_superuser"
                                    :options="[
                                        { text: 'Ja', value: true },
                                        { text: 'Nej', value: false },
                                    ]"
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Verksamhetsadministratör"
                                    name="is_verksamhetsadmin"
                                    v-model="userData.is_verksamhetsadmin"
                                    :options="[
                                        { text: 'Ja', value: true },
                                        { text: 'Nej', value: false },
                                    ]"
                                />
                            </div>
                        </collapse-transition>
                    </div>
                </div>
                <div
                    class="k3-sub-form-flex-container"
                    :id="profile.pk || 'new_profile_' + index"
                    v-for="(profile, index) in profileData"
                    :key="'profile-' + index"
                >
                    <div>
                        <div class="circle">
                            <div class="number">{{ index + 2 }}</div>
                        </div>
                        <div :class="{ vr: true, 'vr-no-min-height': index === profileData.length - 1 }"></div>
                    </div>
                    <div>
                        <div class="left-pad" @click="toggleVisible('profile' + index)">
                            <h3>
                                Profil{{ index !== 0 ? " nr " + (index + 1) : ""
                                }}<img
                                    :class="['k3-dropdown-icon', !hidden.user ? 'k3-img-rotate' : '']"
                                    src="@/assets/dropdown-arrow-blue.svg"
                                />
                                <button
                                    type="button"
                                    @click.stop="deleteProfileForm(index)"
                                    class="k3-delete-button-round"
                                    v-if="index !== 0"
                                >
                                    <div class="k3-delete-circle"><img src="@/assets/delete_white.svg" /></div>
                                    Radera profil nr {{ index + 1 }}
                                </button>
                            </h3>
                        </div>
                        <collapse-transition>
                            <div class="k3-sub-form" v-if="!hidden['profile' + index]">
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Yrkeskategori"
                                    information="Val av yrkeskategori styr behörigheter i Kliniken"
                                    name="yrkeskategori"
                                    v-model="profileData[index].yrkeskategori"
                                    :required="true"
                                    :options="
                                        yrkeskategorier.map((el) => {
                                            return { value: el.id, text: el.displayName };
                                        })
                                    "
                                    @update="yrkeskategoriChanged(index)"
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Roll i Kliniken"
                                    name="roll"
                                    v-model="profileData[index].roll"
                                    apisearch="/core/kodverk/roller/"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    :disabled="editSelf"
                                    label="Förskrivarkod"
                                    name="personalPrescriptionCode"
                                    v-model="profileData[index].personalPrescriptionCode"
                                    :maxlength="7"
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="
                                        editSelf || profileData[index]?.yrkeskategori !== getYrkeskategoriId('LK')
                                    "
                                    label="Specialistkompetens"
                                    name="specialistkompetens"
                                    v-model="profileData[index].specialistkompetens"
                                    apisearch="/core/kodverk/specialistkompetenser/"
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Vårdenhet"
                                    name="orgUnit"
                                    v-model="profileData[index].orgUnit"
                                    :required="true"
                                    :options="getOrgUnitsForProfile(profileData[index].orgUnit)"
                                    :warning="getWarning(profileData[index].orgUnit)"
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Vårdteam"
                                    name="vardteam"
                                    v-model="profileData[index].vardteam"
                                    apisearch="/core/vardteam/"
                                    :map="
                                        (el) => {
                                            return { value: el.id, text: el.name };
                                        }
                                    "
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Signeringsansvarig"
                                    name="signeringsansvarig"
                                    v-model="profileData[index].signeringsansvarig"
                                    :options="[
                                        { text: 'Ja', value: true },
                                        { text: 'Nej', value: false },
                                    ]"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="Telefonnummer"
                                    name="telephoneNumber"
                                    v-model="profileData[index].telephoneNumber"
                                    :maxlength="20"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    label="Mobiltelefonnummer"
                                    name="mobileTelephoneNumber"
                                    v-model="profileData[index].mobileTelephoneNumber"
                                    :maxlength="20"
                                />
                                <input-wrapper
                                    :component="widgets.SelectWidget"
                                    :disabled="editSelf"
                                    label="Enhetsadministratör"
                                    name="enhetsadministrator"
                                    v-model="profileData[index].enhetsadministrator"
                                    :options="[
                                        { text: 'Ja', value: true },
                                        { text: 'Nej', value: false },
                                    ]"
                                />

                                <h4 class="colspan whole">Profilens status</h4>
                                <input-wrapper
                                    :component="widgets.RadioButtonWidget"
                                    :disabled="editSelf || editOwnUser"
                                    :name="index + '-is_active'"
                                    :colspan="2"
                                    v-model="profileData[index].is_active"
                                    :options="[{ text: 'Aktiv profil', value: true }]"
                                />
                                <input-wrapper
                                    :component="widgets.RadioButtonWidget"
                                    :disabled="editSelf || editOwnUser"
                                    :name="index + '-is_active'"
                                    :colspan="2"
                                    v-model="profileData[index].is_active"
                                    :options="[{ text: 'Inaktiv profil', value: false }]"
                                    information="När en profil inte längre ska användas sätts den som inaktiv. Profiler som är inaktiva visas inte i listningar."
                                />

                                <!--                               Inaktiverad till finns specs beslut        -->
                                <!--                                                                          -->
                                <!--                                <input-wrapper-->
                                <!--                                    :component="widgets.DateWidget"-->
                                <!--                                    :disabled="editSelf || editOwnUser"-->
                                <!--                                    name="is_active_from"-->
                                <!--                                    v-model="profileData[index].is_active_from"-->
                                <!--                                    label="Profil aktiv från och med"-->
                                <!--                                />-->
                                <!--                                <input-wrapper-->
                                <!--                                    :component="widgets.DateWidget"-->
                                <!--                                    :disabled="editSelf || editOwnUser"-->
                                <!--                                    name="is_active_to"-->
                                <!--                                    v-model="profileData[index].is_active_to"-->
                                <!--                                    label="Profil aktiv till och med"-->
                                <!--                                />-->

                                <h4 class="colspan whole">Profilens kopplingar till externa system</h4>
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    :disabled="editSelf"
                                    label="Personlig kombika"
                                    :maxlength="20"
                                    name="kombika"
                                    v-model="profileData[index].kombika"
                                />
                                <input-wrapper
                                    :component="widgets.TextWidget"
                                    :disabled="editSelf"
                                    label="Labportalen"
                                    name="labbportalid"
                                    v-model="profileData[index].labbportalid"
                                />
                                <div class="colspan c4">&nbsp;</div>
                            </div>
                        </collapse-transition>
                    </div>
                </div>
            </div>
            <hollow-button
                :icon="PlusCircleBlueIcon"
                label="Lägg till ytterligare profil till användaren"
                @click="addProfileForm()"
            />
            <ButtonGroup class="form-buttons">
                <Button size="primary" role="button" type="submit">Spara användare</Button>
                <Button @click="close()" size="secondary" type="button">Avbryt</Button>
                <Button @click="clearForm" variant="alert" size="small" type="button" role="button"
                    >Rensa alla fält
                </Button>
            </ButtonGroup>
        </form>
    </div>
</template>

<script>
import BaseTab from "@/tabs/mixins/BaseTab";
import InputWrapper from "@/components/widgets/inputs/InputWrapper.vue";
import Vue from "vue";
import dialogEvents from "@/dialogEvents";
import tabIcon from "@/assets/lagg_till_anvandare.svg";
import widgets from "@/components/widgets/inputs";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import { cloneDeep, differenceWith, isEmpty, isEqual } from "lodash";
import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import { mapGetters } from "vuex";
import AdminLockBorder from "@/components/AdminLockBorder.vue";
import Button from "@/components/Button.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import HollowButton from "@/components/widgets/HollowButton.vue";
import PlusCircleBlueIcon from "@/assets/plus_circle_blue.svg";

const defaultUserData = {
    is_active: true,
};

const defaultProfileData = [
    {
        is_active: true,
    },
];
export default {
    extends: BaseTab,
    name: "Anvandare",
    tabName: "Skapa ny användare",
    icon: tabIcon,
    components: {
        HollowButton,
        Button,
        ButtonGroup,
        AdminLockBorder,
        ...widgets,
        InputWrapper,
        CollapseTransition,
    },
    props: {
        tabId: Number,
        parameters: {
            type: Object,
            required: false,
        },
        uid: Number,
    },
    data() {
        return {
            PlusCircleBlueIcon,
            defaultUserData: cloneDeep(defaultUserData),
            userData: cloneDeep(defaultUserData),
            defaultProfileData: cloneDeep(defaultProfileData),
            profileData: cloneDeep(defaultProfileData),
            widgets: widgets,
            hidden: {},
        };
    },
    computed: {
        ...mapGetters("userAdmin", ["yrkeskategorier", "user", "profiles", "status"]),
        ...mapGetters({ ownUser: "userData/user" }),
        ...mapGetters("orgUnit", ["orgUnits"]),
        hasUnsavedData() {
            return (
                !isEqual(this.defaultUserData, this.removeEmpty(this.userData)) ||
                !isEmpty(
                    differenceWith(
                        this.defaultProfileData,
                        this.profileData.map((i) => this.removeEmpty(i)),
                        isEqual
                    )
                )
            );
        },
        editSelf() {
            return (this.parameters && this.parameters.editSelf) || false;
        },
        editOwnUser() {
            if (!this.user) return false;
            return this.user.pk === this.ownUser.pk;
        },
    },
    methods: {
        removeEmpty(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([_, v]) => v != null && v !== "")
                    .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v], this)
            );
        },
        getWarning(orgunit) {
            let orgUnit = this.orgUnits.find((item) => {
                return item.id === orgunit;
            });
            return orgUnit && orgUnit.is_active === false ? "Denna enhet är inaktiverad" : null;
        },
        getOrgUnitsForProfile(orgUnitId) {
            return this.orgUnits
                .filter((item) => {
                    return item.is_active || item.id === orgUnitId;
                })
                .map((el) => {
                    return { text: el.name, value: el.id };
                });
        },
        getYrkeskategoriId(yrkeskategoriCode) {
            const item = this.yrkeskategorier.find((element) => element.code === yrkeskategoriCode);
            return item.id;
        },
        yrkeskategoriChanged(index) {
            if (this.profileData[index].yrkeskategori !== this.getYrkeskategoriId("LK")) {
                this.profileData[index].specialistkompetens = null;
            }
        },
        cleanSpecialistkompetens() {
            this.profileData.forEach((profile) => {
                if (profile.yrkeskategori !== this.getYrkeskategoriId("LK")) {
                    profile.specialistkompetens = null;
                    delete profile.specialistkompetens_display;
                }
            });
        },
        validateForm(formName) {
            var form = document.getElementById(formName);
            if (!form || form.checkValidity() === true) return true;
            else {
                var list = form.querySelectorAll(":invalid");
                for (var item of list) {
                    item.focus();
                }
                form.querySelector(":invalid").focus();
            }
            return false;
        },
        toggleVisible(subFormName) {
            let hidden = this.hidden[subFormName] || false;
            Vue.set(this.hidden, subFormName, !hidden);
        },
        async loadUser() {
            if (this.user && this.profiles) {
                this.defaultUserData = cloneDeep(this.user);
                this.userData = cloneDeep(this.user);
                this.defaultProfileData = cloneDeep(this.profiles);
                this.profileData = cloneDeep(this.profiles);
                this.$store.commit("tabs/setTabName", { id: this.tabId, name: "Redigera användare" });
                if (this.parameters && this.parameters.profileId) {
                    let id = this.parameters.profileId;
                    if (id === "new") {
                        this.profileData.push({
                            is_active: true,
                        });
                        id = "new_profile_" + (this.profileData.length - 1);
                    }
                    this.$nextTick(() => {
                        this.$el.querySelector("#" + id).scrollIntoView({ behavior: "smooth" });
                    });
                }
            }
        },
        async submit() {
            let validated = this.validateForm("user-registration-details");
            if (!validated) return;
            this.cleanSpecialistkompetens();
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: true });
            await this.$store.commit("userAdmin/setUser", this.userData);
            await this.$store.commit("userAdmin/setProfiles", this.profileData);
            await this.$store.dispatch("userAdmin/save");
            this.$store.dispatch("tabs/displaySpinner", { id: this.tabId, display: false });
            if (this.status === "saved_profiles") {
                this.close();
            }
        },
        async refetchProfile() {
            // Refetch the newly updated profile
            await klinikenApi
                .get("/core/users/current/profiles/", getConfig())
                .then((response) => {
                    let profiles = response.data.results;
                    this.$store.dispatch("userData/loadProfiles", { profiles: profiles });
                    this.$store.commit("userData/setCurrentProfile", sessionStorage.getItem("Profile"));
                })
                .catch((e) => {
                    getErrorMessage(e);
                });
        },
        async close() {
            this.closeTab(this.hasUnsavedData);
            await this.refetchProfile();
        },
        addProfileForm() {
            this.profileData.push({
                is_active: true,
            });
        },
        deleteProfileForm(index) {
            this.profileData.splice(index, 1);
        },
        clearForm() {
            dialogEvents.$emit("open", {
                type: "warning",
                description: "Vill du tömma alla fält?",
                buttons: [
                    {
                        title: "Nej",
                        type: "secondary",
                    },
                    {
                        title: "Ja",
                        type: "primary",
                        action: () => {
                            this.userData = {
                                is_active: true,
                            };
                            for (let i = 0; i < this.profileData.length; i++) {
                                Vue.set(this.profileData, i, {
                                    is_active: true,
                                });
                            }
                        },
                    },
                ],
            });
        },
    },
    async created() {
        this.loadUser();
        await this.$store.dispatch("orgUnit/load");
    },
    beforeDestroy() {
        this.$store.commit("userAdmin/clearUser");
    },
};
</script>

<style lang="scss" scoped>
.anvandare {
    .button-group.form-buttons {
        button:last-of-type {
            margin-left: auto;
        }
    }
}
</style>

<style lang="scss" scoped>
@import "@/style/deprecated_main";
</style>

<style lang="sass" scoped>
#user-registration-details
    position: relative

h2
    padding-left: 10px
    margin-bottom: 10px

.margin-left
    margin-left: 10px

h3
    height: 28px
    color: #277692
    font-family: Roboto
    font-size: 20px
    letter-spacing: 0
    line-height: 28px
    display: inline-block
    margin-left: 7px

h4
    color: #277692
    font-family: Roboto
    font-size: 17px
    letter-spacing: 0
    line-height: 28px

.fixed-width
    max-width: 700px
    margin-bottom: 29px

.circle
    height: 26px
    width: 26px
    background-color: #277692
    border-radius: 13px

    .number
        height: 26px
        width: 100%
        color: #FFFFFF
        font-family: Roboto
        font-size: 18px
        letter-spacing: 0
        line-height: 26px
        text-align: center

.vr
    border-left: 1px solid #8EBACA
    height: calc(100% - 60px)
    min-height: 10px
    margin-left: 13px
    margin-top: 10px
    margin-bottom: 10px

    &.vr-no-min-height
        min-height: 0px

.k3-lock-border
    height: 26px
    width: 350px
    background: linear-gradient(134.72deg, #C2E6E6 0%, rgba(194, 230, 228, 0) 100%)
    color: #277692
    font-family: Roboto
    font-size: 13px
    font-weight: 500
    letter-spacing: 0.46px
    line-height: 26px
    margin-bottom: 18px
    margin-left: 10px

    img
        margin: -2px 6px 0px 17px

.k3-form
    flex-flow: column nowrap

.k3-sub-form-flex-container
    display: flex
    flex-direction: row
    margin-left: -20px

.k3-sub-form
    display: flex
    flex-direction: row
    flex-wrap: wrap

.k3-dropdown-icon
    margin-left: 15px

.k3-img-rotate
    transform: rotate(180deg)

.k3-delete-button-round
    border: none
    background-color: inherit
    margin-left: 100px
    color: #354052
    font-family: Roboto
    font-size: 16px
    letter-spacing: 0
    line-height: 28px
    margin-right: 8px

    .k3-delete-circle
        height: 28px
        width: 28px
        border-radius: 50%
        background: linear-gradient(138.21deg, #CA346F 0%, #EC4E6E 100%)
        border: none
        display: inline-block

        img
            height: 16px
            width: 14px
            margin-top: -2px

.hollow-button
    height: 28px
    color: #6C8188
    font-family: Roboto Medium
    font-size: 16px
    font-weight: 500
    line-height: 28px
    border: none
    background-color: #FFFFFF

    img
        margin-right: 8px
</style>
