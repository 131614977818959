import { getConfig, getErrorMessage, klinikenApi } from "@/api";
import tabEvents from "@/tabEvents";
import { flashMessage, openDialog } from "@/utils";
import Anvandare from "@/tabs/Admin/Anvandare.vue";
import Vue from "vue";

import { cloneDeep } from "lodash";

const getDefaultState = () => {
    return {
        occupationalCodes: [],
        user: null,
        profiles: null,
        profilesSearchResult: [],
        status: STATUS_EMPTY,
    };
};

const STATUS_EMPTY = "empty";
const STATUS_MODIFIED = "modified";
const STATUS_SAVING_USER = "savinguser";
const STATUS_SAVING_PROFILES = "savingprofiles";
const STATUS_SAVED_USER = "saveduser";
const STATUS_SAVED_PROFILES = "saved_profiles";
const WORKING_STATUSES = [STATUS_SAVING_USER, STATUS_SAVING_PROFILES];

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        setYrkeskoder(state, yrken) {
            yrken.forEach((el) => {
                state.occupationalCodes.push(el);
            });
        },
        setUser(state, user) {
            if (state.user && state.user.pk && user && user.pk)
                if (state.user.pk !== user.pk)
                    // Failsafe: if active user changes, reset profiles
                    state.profiles = null;
            user = cloneDeep(user);
            // setUser is called twice when editing a user. First upon load from backend, second before save.
            // hsautfardare and hsalopnummer should only be set from hsaid in the first case; when editing
            // it should instead use the form fields to make up hsaid (via baseUser getter).
            if (!user.hsautfardare && !user.hsalopnummer && user.hsaid && user.hsaid.indexOf("-") !== -1) {
                let temphasid = user.hsaid.split("-");
                user.hsautfardare = temphasid[0];
                user.hsalopnummer = temphasid[1];
            }
            state.user = user;
            state.status = STATUS_MODIFIED;
        },
        clearUser(state) {
            state.user = null;
            state.profiles = null;
            state.status = STATUS_EMPTY;
        },
        setProfiles(state, profiles) {
            state.profiles = cloneDeep(profiles);
            state.status = STATUS_MODIFIED;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setProfilesSearchResult(state, profiles) {
            state.profilesSearchResult = profiles;
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        profiles(state) {
            return state.profiles;
        },
        isWorking(state) {
            return WORKING_STATUSES.includes(state.status);
        },
        status(state) {
            return state.status;
        },
        baseUser(state) {
            if (!state.user) return null;
            let baseUser = cloneDeep(state.user);
            if (baseUser.hsautfardare && baseUser.hsalopnummer)
                baseUser.hsaid = baseUser.hsautfardare + "-" + baseUser.hsalopnummer;
            delete baseUser.hsautfardare;
            delete baseUser.hsalopnummer;
            return baseUser;
        },
        baseProfiles(state) {
            if (!(state.user && state.profiles)) return [];
            let baseProfiles = cloneDeep(state.profiles);
            baseProfiles.forEach((el) => {
                el.user = state.user.pk;
                if (typeof el.orgUnit === "object") el.orgUnit = el.orgUnit.pk;
            });
            return baseProfiles;
        },
        baseProfileGetter() {
            return (profile) => {
                let baseProfile = cloneDeep(profile);
                if (baseProfile.user && typeof baseProfile.user === "object") baseProfile.user = baseProfile.user.pk;
                if (baseProfile.orgUnit && typeof baseProfile.orgUnit === "object")
                    baseProfile.orgUnit = baseProfile.orgUnit.id;
                if (baseProfile.yrkeskategori && typeof baseProfile.yrkeskategori === "object")
                    baseProfile.yrkeskategori = baseProfile.yrkeskategori.id;
                if (baseProfile.roll && typeof baseProfile.roll === "object") baseProfile.roll = baseProfile.roll.id;

                return baseProfile;
            };
        },
        sortedProfilesSearchResult(state, getters, rootState, rootGetters) {
            let profiles = cloneDeep(state.profilesSearchResult);
            let currentProfileId = rootState.userData.currentProfile;
            let currentProfileIndex = profiles.findIndex((el) => {
                return el.pk === currentProfileId;
            });
            Vue.set(profiles, currentProfileIndex, {
                ...profiles[currentProfileIndex],
                ...{ is_current_profile: true },
            });
            profiles.sort(function (a, b) {
                var nameA = a.user.username.toUpperCase(); // ignore upper and lowercase
                var nameB = b.user.username.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                if (a.is_active && !b.is_active) return -1;
                if (!a.is_active && b.is_active) return 1;

                if (a.created_at < b.created_at) return -1;
                if (a.created_at > b.created_at) return 1;

                return 0;
            });

            //TODO: add to sort that inactive should be placed last

            let previousUserName = null;
            profiles.forEach((el) => {
                if (el.user.username === previousUserName) el.user.userrow = true;
                if (el.user.pk === rootGetters["userData/user"].pk) el.user.currentuser = true;
                previousUserName = el.user.username;
            });
            return profiles;
        },
        activeSortedProfilesSearchResult(state, getters, rootState, rootGetters) {
            let profiles = cloneDeep(state.profilesSearchResult).filter(
                (item) => item.is_active && item.user.is_active
            );
            let currentProfileId = rootState.userData.currentProfile;
            let currentProfileIndex = profiles.findIndex((el) => {
                return el.pk === currentProfileId;
            });
            Vue.set(profiles, currentProfileIndex, {
                ...profiles[currentProfileIndex],
                ...{ is_current_profile: true },
            });
            profiles.sort(function (a, b) {
                var nameA = a.user.username.toUpperCase(); // ignore upper and lowercase
                var nameB = b.user.username.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                if (a.is_active && !b.is_active) return -1;
                if (!a.is_active && b.is_active) return 1;

                if (a.created_at < b.created_at) return -1;
                if (a.created_at > b.created_at) return 1;

                return 0;
            });

            //TODO: add to sort that inactive should be placed last

            let previousUserName = null;
            profiles.forEach((el) => {
                if (el.user.username === previousUserName) el.user.userrow = true;
                if (el.user.pk === rootGetters["userData/user"].pk) el.user.currentuser = true;
                previousUserName = el.user.username;
            });
            return profiles;
        },
        yrkeskategorier(state) {
            let yrkeskategorier = cloneDeep(state.occupationalCodes);
            yrkeskategorier.sort(function (a, b) {
                var nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
                var nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            return yrkeskategorier;
        },
        /**
         * Get the occupation of any user profile with (profile) as an argument
         */
        getOccupation(state, getters) {
            return (userProfile) => {
                let yrkeskategori = userProfile.yrkeskategori;
                let occupationalCode = getters.yrkeskategorier.find((el) => {
                    return el.id === yrkeskategori.id;
                });
                if (occupationalCode) return occupationalCode.displayName;
                else return "Annat";
            };
        },
        getUserFromProfile(state, getters) {
            return (profile) => {
                let user = profile.user;
                let userProfiles = getters.sortedProfilesSearchResult
                    .filter((el) => {
                        return el.user.pk === user.pk;
                    })
                    .map((el) => {
                        let newEl = cloneDeep(el);
                        newEl.user = el.user.pk;
                        return newEl;
                    });
                user.profiles = userProfiles;
                return user;
            };
        },
    },
    actions: {
        async loadYrkeskoder({ commit }) {
            const config = getConfig();
            return klinikenApi
                .get("core/kodverk/yrkeskategorier/", config)
                .then((response) => {
                    commit("setYrkeskoder", response.data.results);
                })
                .catch(() => {});
        },
        async saveUser({ getters, commit }) {
            let baseUser = getters.baseUser;
            const config = getConfig();
            commit("setStatus", STATUS_SAVING_USER);
            return (
                baseUser.pk
                    ? klinikenApi.put("/core/users/" + baseUser.pk + "/", baseUser, config)
                    : klinikenApi.post("/core/users/", baseUser, config)
            )
                .then((response) => {
                    commit("setUser", response.data);
                    commit("setStatus", STATUS_SAVED_USER);
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                    commit("setStatus", STATUS_MODIFIED);
                });
        },
        async saveProfiles({ getters, commit }) {
            const config = getConfig();
            if (getters.status !== STATUS_SAVED_USER) {
                return;
            }
            let baseProfiles = getters.baseProfiles;
            let profiles = [];
            commit("setStatus", STATUS_SAVING_PROFILES);
            let errorCount = 0;
            for (let i = 0; i < baseProfiles.length; i++) {
                await (baseProfiles[i].pk
                    ? klinikenApi.put("/core/profiles/" + baseProfiles[i].pk + "/", baseProfiles[i], config)
                    : klinikenApi.post("/core/profiles/", baseProfiles[i], config)
                )
                    .then((response) => {
                        profiles.push(response.data);
                    })
                    .catch((e) => {
                        openDialog(getErrorMessage(e), "error");
                        profiles.push(baseProfiles[i]);
                        errorCount++;
                    });
            }
            commit("setProfiles", profiles);
            commit("setStatus", errorCount === 0 ? STATUS_SAVED_PROFILES : STATUS_MODIFIED);
            if (errorCount > 0)
                flashMessage(
                    "Användare och " +
                        (baseProfiles.length - errorCount) +
                        " av " +
                        baseProfiles.length +
                        " profiler sparade"
                );
            else flashMessage("Användare och profiler sparade");
            return new Promise((resolve) => resolve());
        },
        async setInactiveProfile({ getters }, profile) {
            const config = getConfig();
            let baseProfile = getters.baseProfileGetter(profile);
            baseProfile.is_active = false;
            return klinikenApi
                .put("/core/profiles/" + baseProfile.pk + "/", baseProfile, config)
                .then(() => {
                    flashMessage("Profilen inaktiverad.");
                    tabEvents.$emit("refreshUsers");
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async setActiveProfile({ getters }, profile) {
            const config = getConfig();
            let baseProfile = getters.baseProfileGetter(profile);
            baseProfile.is_active = true;
            return klinikenApi
                .put("/core/profiles/" + baseProfile.pk + "/", baseProfile, config)
                .then(() => {
                    flashMessage("Profilen aktiverad.");
                    tabEvents.$emit("refreshUsers");
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async setInactiveUser(store, profile) {
            const config = getConfig();
            let user = cloneDeep(profile.user);
            user.is_active = false;
            return klinikenApi
                .put("/core/users/" + user.pk + "/", user, config)
                .then(() => {
                    flashMessage("Användaren inaktiverad.");
                    tabEvents.$emit("refreshUsers");
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async setActiveUser(state, profile) {
            const config = getConfig();
            let user = cloneDeep(profile.user);
            user.is_active = true;
            return klinikenApi
                .put("/core/users/" + user.pk + "/", user, config)
                .then(() => {
                    flashMessage("Användaren aktiverad.");
                    tabEvents.$emit("refreshUsers");
                })
                .catch((e) => {
                    openDialog(getErrorMessage(e), "error");
                });
        },
        async save({ dispatch }) {
            await dispatch("saveUser");
            await dispatch("saveProfiles");
            tabEvents.$emit("refreshUsers");
        },
        async editUser({ commit, dispatch, getters }, profile) {
            let component = Anvandare;
            await dispatch("tabs/closeTab", component, { root: true });

            let user = getters.getUserFromProfile(profile);
            let profiles = user.profiles;
            delete user.profiles;
            commit("setUser", user);
            commit("setProfiles", profiles);

            await dispatch("tabs/openTab", { component }, { root: true });
        },
        async editSelf({ commit, dispatch, rootGetters }) {
            let component = Anvandare;
            await dispatch("tabs/closeTab", component, { root: true });

            let user = rootGetters["userData/user"];
            let profiles = rootGetters["userData/profiles"];

            commit("setUser", user);
            commit("setProfiles", profiles);

            await dispatch("tabs/openTab", { component, parameters: { editSelf: true } }, { root: true });
        },
        async editProfile({ commit, dispatch, getters }, profile) {
            let component = Anvandare;
            await dispatch("tabs/closeTab", component, { root: true });

            let user = getters.getUserFromProfile(profile);
            let profiles = user.profiles;
            delete user.profiles;
            commit("setUser", user);
            commit("setProfiles", profiles);

            await dispatch("tabs/openTab", { component, parameters: { profileId: profile.pk } }, { root: true });
        },
        async addProfile({ commit, dispatch, getters }, profile) {
            let component = Anvandare;
            await dispatch("tabs/closeTab", component, { root: true });

            let user = getters.getUserFromProfile(profile);
            let profiles = user.profiles;
            delete user.profiles;
            commit("setUser", user);
            commit("setProfiles", profiles);

            await dispatch("tabs/openTab", { component, parameters: { profileId: "new" } }, { root: true });
        },
        async fetchProfilesSearchResults({ commit }, params) {
            return klinikenApi
                .get("/core/profiles/", getConfig({ params }))
                .then((response) => {
                    commit("setProfilesSearchResult", response.data.results);
                })
                .catch((e) => {
                    getErrorMessage(e);
                });
        },
    },
};
