import Vue from "vue";
import Vuex from "vuex";
import prescriptionSet from "@/store/modules/prescriptionSet";
import patientData from "@/store/modules/patientData";
import userData from "@/store/modules/userData";
import userAdmin from "@/store/modules/userAdmin";
import systemData from "@/store/modules/systemData";
import tabs from "@/store/modules/tabList";
import dictionary from "@/store/modules/dictionary";
import journalanteckningar from "@/store/modules/journalanteckningar";
import alertInformation from "@/store/modules/alertInformation";
import samtycke from "@/store/modules/samtycke";
import orgUnit from "@/store/modules/orgUnit";
import sokord from "@/store/modules/sokord";
import anteckningstyp from "@/store/modules/anteckningstyp";
import icdkod from "@/store/modules/icdkod";
import kvakod from "@/store/modules/kvakod";
import journalloggar from "@/store/modules/journalloggar";
import intyg from "@/store/modules/intyg";
import filhantering from "@/store/modules/filhantering";
import opCalendar from "@/store/modules/operation/operationskalender";
import operation from "./modules/operation";
import skreg from "@/store/modules/skreg";
import journalanteckningsmall from "@/store/modules/journalanteckningsmall";

Vue.use(Vuex);

const debug = import.meta.NODE_ENV !== "production";

/**
 * NOTE: all modules registered here _must_ implement reset() mutation.
 */

export default new Vuex.Store({
    modules: {
        prescriptionSet,
        patientData,
        userData,
        userAdmin,
        systemData,
        tabs,
        dictionary,
        journalanteckningar,
        alertInformation,
        samtycke,
        orgUnit,
        sokord,
        anteckningstyp,
        icdkod,
        kvakod,
        journalloggar,
        intyg,
        filhantering,
        opCalendar,
        operation,
        skreg,
        journalanteckningsmall,
    },
    strict: debug,
});
